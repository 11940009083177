.wrapper {
    padding: 61px;
    display: flex;
    border-radius: 6px;
    background-size: cover;
}

.title {
    padding-bottom: 16px;
    font-size: 40px;
    line-height: 40px;
}

.description {
    padding: 0;
}

.legalMessage {
    display: inline;
    font-size: inherit;
    line-height: inherit;
}

.icon {
    padding-right: 57px;
    height: 76px;
}

@media (max-width: 768px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
        background-size: auto 100%;
        padding: 35px;
    }

    .title {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
    }

    .description {
        text-align: center;
    }

    .icon {
        padding-bottom: 17px;
        padding-right: 0;
        height: 44px;
    }
}
