.container {
    composes: recentOrders from './recentOrders.vars.css';
    display: flex;
    flex-direction: column;
    padding: var(--recentOrders-container-mobile-padding);
    box-shadow: var(--recentOrders-container-box-shadow);
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        align-items: center;
        padding: var(--recentOrders-container-padding);
    }

    .container > *:first-child {
        margin-right: var(--recentOrders-container-first-child-margin-right);
    }
}
