.viewDetails {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 767px) {
    .wrapper {
        text-align: center;
        z-index: 0;
        font-size: 16px;
        line-height: 20px;
    }
}

.content {
    padding: 48px 0 17px;
    flex-grow: 1;
    overflow-y: auto;
}

.header {
    text-align: start;
    padding: 0px 20px 0;
    margin: 12px 0 48px;
}

.header .subTitle {
    color: var(--col--primary1);
}

.textBlock {
    padding: 0 20px;
    margin-bottom: 17px;
}

.productSubtext {
    color: var(--col--primary1);
    margin-bottom: 4px;
}

.imageWrapper {
    max-width: 375px;
    max-height: 281px;
}

.imageWrapper img {
    width: 100%;
    height: 100%;
}

.statementText {
    padding: 0 20px;
    margin-top: 23px;
}

.productText {
    margin-bottom: 4px;
}

@media (min-width: 960px) {
    .content {
        padding: 58px 40px 30px 520px;
    }

    .header {
        width: 100%;
        margin: 0 0 40px;
    }

    .mainContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0;
    }

    .productText {
        margin-bottom: 15px;
    }

    .textBlock,
    .statementText {
        max-width: 502px;
        margin-bottom: 20px;
        padding: 0 47px 0 0;
    }

    .statementText {
        margin-top: 0;
    }

    .productSubtext {
        margin-bottom: 5px;
    }

    .buttonsBlock {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 0 40px 28px 0;
    }

    .mainLink {
        margin-left: 20px;
    }

    .imageWrapper {
        position: absolute;
        top: 0;
        left: -480px;
        margin-right: 80px;
        max-width: 400px;
        max-height: 300px;
    }
}

@media (max-width: 960px) {
    .button {
        width: 100%;
        height: 60px;
    }

    .buttonsBlock {
        width: 100%;
        display: flex;
        height: 50px;
    }

    .mobileMainLink {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 1px;
    }

    .mobileSecondaryLink {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .mainLink,
    .secondaryLink {
        flex-grow: 1;
    }
}
