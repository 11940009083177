.infoBanner {
    --banner-paddings: 75px 0 50px 0;
    --banner-paddings-mobile: 35px 20px;
    --description-margin-top: 18px;
    --description-margin-top-mobile: 6px;
    --description-title-font-size: 22px;
    --description-main-font-size: 52px;
    --description-main-line-height: 52px;
    --description-title-text-transform: uppercase;
    --description-title-font-family: var(--ff--subheader);
}

@media (max-width: 960px) {
    .infoBanner {
        --description-main-font-size: 32px;
        --description-main-line-height: 34px;
    }
}
