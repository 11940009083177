.wrapperBackground {
    display: flex;
    height: 200px;
}

.textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 221px;
    padding-left: 52px;
}

.textBlock .subTitle {
    color: var(--col--secondary1);
}

.textBlock .title {
    color: var(--col--dark);
}

.eventCards {
    display: flex;
    align-items: center;
    margin-left: 16px;
    border-radius: 6px;
    max-width: calc(100% - 221px - 52px - 16px - 52px);
}

.scroller {
    overflow: hidden;
    display: flex;
}

.prevButton {
    left: 0;
}

.nextButton {
    right: 0;
}

.prevButton:hover,
.nextButton:hover {
    background-color: var(--col--primary1);
}

@media (min-width: 1441px) {
    .wrapperBackground {
        padding: 0 240px;
    }
}

@media (max-width: 1440px) {
    .scrollerList {
        padding-right: 20px;
    }

    .eventCards {
        max-width: calc(100% - 221px - 52px - 16px);
    }
}

@media (max-width: 767px) {
    .wrapperBackground {
        flex-direction: column;
        padding: 30px 0 30px 20px;
        height: auto;
        max-height: 249px;
    }

    .textBlock {
        padding-left: 0;
        margin-bottom: 15px;
    }

    .wrapperBackground .items {
        overflow-x: auto;
    }

    .eventCards {
        margin-left: 0;
        max-width: 100%;
        padding-right: 20px;
    }
}
