.sectionFlexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.menuCategoryCard {
    z-index: 1;
    height: 160px;
    margin: 10px 10px 10px 0px;
    display: flex;
    flex: 1;
    flex-basis: 30%;
    min-width: 300px;
}

/* 3 column */
.hideWhenWide {
    display: none !important;
}

.showWhenWide {
    display: block !important;
}

.hidden {
    display: none;
}

/* 2 column */
@media (max-width: 1200px) {
    .menuCategoryCard {
        flex-basis: 40%;
    }

    .hideWhenWide {
        display: block !important;
    }

    .showWhenWide {
        display: none !important;
    }
}

.sectionFooter {
    background: transparent;
}

.backgroundBottomText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    color: var(--col--light);
    font-family: var(--subhead-font);
    text-transform: uppercase;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    padding-left: 20px;
    white-space: nowrap;
    letter-spacing: 0.01em;
    font-weight: 700;
}

.backgroundBottomText div:last-child {
    font-family: var(--head-font);
    font-size: 52px;
    line-height: 52px;
    text-transform: uppercase;
    padding-top: 0;
    font-weight: 900;
}

@media (max-width: 425px) {
    .backgroundBottomText {
        padding: 0;
        font-size: 16px;
        line-height: 18px;
    }

    .backgroundBottomText div:last-child {
        font-size: 34px;
        line-height: 34px;
    }

    .backgroundBottomTextSection {
        height: 80px;
        margin: 0;
        padding: 0;
    }
}
