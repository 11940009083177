.wrapper {
    --partnerImg-height: auto;
    --partnerLink-overflow: hidden;
    --partnerLink-width: 240px;
    --partnerLink-UberEats-border: none;
    --partnerLink-DoorDash-border: none;
    --partnerLink-GrubHub-border: none;
    --partnerImg-margin-top: 40px;
    --contentTitle-font-size-mobile: 28px;
    --contentTitle-line-height-mobile: 28px;
}
