.container {
    display: flex;
}

@media (min-width: 768px) {
    .container {
        overflow-x: hidden;
    }

    .container .prevButton {
        left: 0;
    }

    .container .nextButton {
        right: 0;
    }
}
