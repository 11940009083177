.title {
    composes: title from './index.vars.css';
    margin: var(--title-margin-mobile);
    padding: 0;
}

.container {
    composes: container from './index.vars.css';
    padding: var(--container-padding-mobile);
    margin: var(--container-margin-mobile);
}

.subtitle {
    margin: 0;
    padding-top: var(--subtitle-padding-top-mobile);
    margin-bottom: 10px;
    color: var(--subtitle-color);
    border-top: var(--subtitle-border-top);
}

.accordionItems {
    margin-top: 25px;
}

.text > *:first-child {
    margin-top: 0;
}

.text a {
    font-family: var(--base-font);
    text-decoration: underline;
}

.text p {
    max-width: var(--text-paragraph-max-width, 800px);
}

.text h4 {
    font-family: var(--head-font);
    font-size: 16px;
    line-height: 16px;
}

.text p b {
    font-family: var(--heavy-font);
}

.text ul {
    margin-top: 0;
    margin-bottom: 26px;
    padding-left: 0;
}

.text * {
    margin-bottom: 0;
}

@media (min-width: 960px) {
    .container {
        padding: var(--container-padding);
        margin: var(--container-margin);
    }

    .title {
        margin: var(--title-margin);
    }

    .subtitle {
        padding-top: var(--subtitle-padding-top);
    }

    .text h4 {
        font-size: 20px;
        line-height: 20px;
    }
}
