.wrapper {
    margin-left: 52px;
    margin-right: 52px;
}

.content {
    display: flex;
    flex-direction: row;
}

.tab {
    min-height: 54px;
}

.tabSet {
    min-height: 54px;
}

.overview {
    width: 500px;
    margin-top: 60px;
    margin-right: 38px;
    display: flex;
    flex-direction: column;
}

.overview h1 {
    margin: 35px 0px 15px 20px;
}

.overview p {
    margin-left: 20px;
}

.overview img {
    align-self: center;
    width: 100%;
}

.tabIndicator {
    background-color: var(--col--primary2);
    height: 4px;
}

.tabs {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
}

.tabsHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--col--gray4);
}

.tabs :global(span.brand-icon) {
    font-size: 18px;
}

.sizeHeader {
    color: var(--col--gray6);
    padding-right: 24px;
    white-space: nowrap;
}

.mobileSizeHeader {
    display: none;
}

.sizeValue {
    color: var(--col--gray6);
    white-space: nowrap;
    text-align: right;
}

.beerListRow {
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 8px 20px;
    border-bottom: 1px solid var(--col--gray4);
}

.sectionHeader {
    padding-bottom: 29px;
}

.caloriesUnavailable,
.caloriesUnavailableMobile {
    color: var(--col--gray6);
    font-weight: 500;
}

.caloriesUnavailableMobile {
    display: none;
}

.caloriesAvailable {
    color: var(--col--gray5);
    font-size: 14px;
    line-height: 18px;
    display: block;
}

.loader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 960px) {
    .wrapper {
        margin-left: 20px;
        margin-right: 20px;
    }

    .content {
        flex-direction: column;
    }

    .overview {
        width: 100%;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .overview h1 {
        margin: 20px 0 10px;
    }

    .overview p {
        margin-left: 0px;
    }

    .sectionHeader {
        padding-bottom: 0px;
    }

    .tab {
        min-height: 44px;
    }

    .tabSet {
        min-height: 44px;
    }

    .overview img {
        align-self: center;
        width: auto;
        height: 244px;
    }

    .caloriesAvailable {
        color: var(--col--gray6);
        font-size: 12px;
        line-height: 16px;
    }
}

@media (max-width: 768px) {
    .mobileSizeHeader {
        display: flex;
        color: var(--col--gray6);
        white-space: nowrap;
        padding: 14px 20px;
        justify-content: flex-end;
        border-bottom: 1px solid var(--col--gray4);
    }

    .sizeHeader {
        display: none;
    }

    .caloriesUnavailableMobile {
        display: block;
    }

    .caloriesUnavailable {
        display: none;
    }

    .beerListRow {
        padding: 12px 20px;
        min-height: 21px;
    }
}
