.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    margin-top: 32px;
    background: var(--col--gray1);
}

.legalMessage {
    display: inline;
    font-size: 14px;
    line-height: 18px;
}

.description {
    color: var(--col--dark);
}

.divider {
    height: 44px;
    margin-left: 15px;
}

.textContent {
    margin-left: 15px;
    color: var(--col--dark);
}

@media screen and (max-width: 959px) {
    .legalMessage {
        font-size: 12px;
        line-height: 16px;
    }

    .container {
        margin-top: 12px;
    }

    .divider {
        height: 50px;
    }
}
