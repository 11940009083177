.wrapper {
    text-align: center;
}

.card {
    display: block;
    box-sizing: border-box;
    padding: 30px 20px;
    border-bottom: 1px solid var(--col--gray2);
}

.withCta {
    padding: 0;
}

.withCta > a {
    padding: 30px 20px;
    flex: 1;
    justify-content: center;
    display: flex;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardContainer:first-child {
    border-top: 1px solid var(--col--gray2);
}

.image {
    width: 200px;
    height: auto;
    margin-bottom: 15px;
}

.title {
    margin: 0;
}

.description {
    margin-top: 2px;

    /** Arbys uses --base-font (Helvetica Neue) instead of --ff--pharagraph (Helvetica) in this component */
    font-family: var(--base-font);
}

.cta {
    margin-top: 15px;
}

.backgroundImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media (min-width: 960px) {
    .wrapper {
        display: flex;
        justify-content: center;
        border-top: 1px solid var(--col--gray2);
        border-bottom: 1px solid var(--col--gray2);
    }

    .cardContainer:first-child {
        border-top: none;
        border-right: 1px solid var(--col--gray2);
    }

    .card {
        border-bottom: none;
        border-top: none;
        display: flex;
        justify-content: center;
        padding: 60px 20px;
    }

    .withCta {
        padding: 0;
    }

    .withCta > a {
        padding: 60px 20px;
    }

    .cardWrapper {
        max-width: 480px;
    }

    .image {
        width: 310px;
    }

    .title {
        height: 104px;
        overflow: hidden;
    }

    .description {
        margin-top: 5px;
    }

    .withCta .description {
        margin-bottom: 30px;
    }

    .title + .cta {
        margin-top: 30px;
    }

    .cta {
        margin-top: auto;
    }
}
