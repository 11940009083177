.wrapper {
    composes: numberedList from './numberedList.vars.css';
}

.title {
    composes: t-header-h2 from global;
    padding: 0 20px;
    margin-bottom: 20px;
}

.description {
    composes: t-paragraph-strong from global;
    margin-bottom: var(--description-mobile-margin-bottom);
}

.listWrapper {
    margin: 0 20px;
    padding: 30px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}

.listWrapper.stylized {
    margin: 0;
    padding: 0;
}

.withoutShadow {
    box-shadow: none;
}

.list {
    margin: 0;
    padding: 0;
}

.list .listItem {
    padding-left: 0px;
}

.listItem {
    margin-bottom: var(--item-mobile-margin-bottom);
}

.stylized .listItem {
    margin-bottom: 0;
    padding: 18px 0;
    border-bottom: 1px solid var(--col--gray4);
}

.listItem::before {
    display: none;
}

.listItem:nth-last-child(1) {
    margin-bottom: 0;
}

.itemTitle {
    composes: t-subheader from global;
    display: flex;
    margin: 0;
    margin-bottom: 14px;
    align-items: center;
}

.stylized .itemTitle {
    margin-bottom: 0;
}

.itemTitle::before {
    content: counter(li);
    padding-right: 9px;
    font-family: var(--ff--subheader);
    color: var(--col--secondary1);
    font-size: 34px;
    line-height: 34px;
}

.stylized .itemTitle::before {
    width: auto;
    margin-right: 0;
    font-size: 18px;
    line-height: 18px;
}

.itemDescription {
    composes: t-paragraph from global;
}

@media (min-width: 960px) {
    .title {
        margin: 0 32px 34px;
    }

    .description {
        margin-bottom: var(--description-margin-bottom);
    }

    .listWrapper {
        margin: 0 52px;
        padding: var(--list-wrapper-padding);
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .stylized .list {
        margin-left: 0;
        display: flex;
        flex-direction: column;
    }

    .listItem {
        box-sizing: border-box;
        width: calc(50% - 50px);
        margin-bottom: var(--item-margin-bottom);
    }

    .listItem:nth-last-child(2) {
        margin-bottom: 0;
    }

    .stylized .listItem {
        margin-bottom: 0;
        padding: 18px 0;
        border-bottom: 1px solid var(--col--gray4);
    }

    .itemTitle {
        margin-bottom: var(--item-title-margin-bottom);
        letter-spacing: 0.01em;
    }

    .stylized .itemTitle {
        margin-bottom: 0;
    }

    .itemTitle::before {
        width: 100%;
        max-width: 29px;
        margin-right: var(--item-number-margin-right);
        font-size: var(--item-number-font-size);
        line-height: var(--item-number-line-height);
        text-align: start;
    }

    .stylized .itemTitle::before {
        width: auto;
        margin-right: 0;
        font-size: 20px;
        line-height: 20px;
    }

    .itemDescription {
        padding-left: var(--item-description-padding-left);
    }
}
