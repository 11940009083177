.wrapper {
    transition: border-left 0.2s;
}

.wrapper.collapsed {
    box-shadow: inset 5px 0 0 var(--col--primary1);
}

.wrapper:last-child {
    border-bottom: solid 1px var(--col--gray4);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    border-top: solid 1px var(--col--gray4);
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
}

.wrapperHeadings {
    min-width: 0;
    max-width: 100%;
    display: flex;
    padding-right: 8px;
}

.header .collapsed {
    color: var(--col--primary1);
}

.text {
    align-self: flex-end;
    min-width: calc(25% - 10px);
    margin-left: 15px;
}

.content {
    padding: 0 30px;
}

@media (min-width: 768px) {
    .text {
        min-width: calc(10% - 10px);
    }
}

@media (min-width: 960px) {
    .header {
        height: 80px;
        padding: 0 26px;
    }

    .text {
        margin-left: 20px;
    }

    .content {
        padding: 0 80px;
    }
}
