.container {
    --container-padding: 0 0 0 48px;
    --container-margin: 0 52px 0 0;
    --container-padding-mobile: 0 0 0 21px;
    --container-margin-mobile: 0 30px 0 0;
    --subtitle-color: var(--col--primary1);
    --subtitle-padding-top: 0;
    --subtitle-padding-top-mobile: 0;
    --subtitle-border-top: none;
    --text-paragraph-max-width: 800px;
}

.title {
    --title-margin: 0 0 10px;
    --title-margin-mobile: 0 0 10px;
}
