.wrapper {
    composes: wrapper from './deliveryPartners.vars.css';
    padding: 0 20px;
}

.sectionTitle {
    padding: 0;
    margin-bottom: 20px;
}

.image {
    width: 100%;
    margin-bottom: 25px;
    border-radius: 6px;
}

.wrapper .contentSubtitle {
    color: var(--col--primary1);
}

.contentTitle {
    margin: 0;
    margin-bottom: 10px;
}

.description {
    margin-bottom: 15px;
}

.partnerLink {
    height: 50px;
    padding: 9px 0;
    box-sizing: border-box;
}

.partnerLink.DoorDash,
.partnerLink.UberEats {
    padding: 12px 0;
}

.partnerLink.GrubHub {
    padding: 11px 0;
}

.partnerLink.Postmates {
    padding: 8px 0;
}

.partnerImg {
    width: auto;
    height: var(--partnerImg-height);
}

.partnerImg.UberEats,
.partnerImg.DoorDash,
.partnerImg.GrubHub {
    margin-top: var(--partnerImg-margin-top);
}

.partnerLink:not(:last-child) {
    margin-bottom: 20px;
}

@media (min-width: 960px) {
    .wrapper {
        padding: 0 52px;
    }

    .innerWrapper {
        display: flex;
        align-items: center;
    }

    .image {
        width: 415px;
        margin-bottom: 0;
        margin-right: 40px;
    }

    .sectionTitle {
        margin-bottom: 30px;
    }

    .contentTitle {
        margin-bottom: 5px;
    }

    .description {
        margin-bottom: 25px;
    }

    .partnersLinks {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        row-gap: 5px;
    }

    .partnerLink {
        width: var(--partnerLink-width);
        padding: 11px 0;
        overflow: var(--partnerLink-overflow);
    }

    .partnerLink.DoorDash {
        padding: 14px 0;
        border: var(--partnerLink-DoorDash-border);
    }

    .partnerLink.UberEats {
        padding: 12px 0;
        border: var(--partnerLink-UberEats-border);
    }

    .partnerLink.GrubHub {
        padding: 11px 0;
        border: var(--partnerLink-GrubHub-border);
    }

    .partnerLink.Postmates {
        padding: 8px 0;
    }

    .partnerLink:not(:last-child) {
        margin-right: 20px;
        margin-bottom: 0px;
    }
}

@media (min-width: 1441px) {
    .wrapper {
        width: 1440px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
    }
}

@media (max-width: 959px) {
    .contentTitle {
        font-size: var(--contentTitle-font-size-mobile);
        line-height: var(--contentTitle-line-height-mobile);
    }

    .partnerLink {
        width: 100%;
    }

    .partnerLink.DoorDash {
        border: var(--partnerLink-DoorDash-border);
    }

    .partnerLink.UberEats {
        border: var(--partnerLink-UberEats-border);
    }

    .partnerLink.GrubHub {
        border: var(--partnerLink-GrubHub-border);
    }
}
