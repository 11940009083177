.container {
    composes: imageBlock from './imageBlock.vars.css';
}

.wrapper {
    padding-left: var(--wrapper-mobile-padding-left);
    padding-right: var(--wrapper-mobile-padding-right);
}

.header {
    display: var(--header-display);
    padding: 0;
    margin-bottom: var(--header-mobile-margin-bottom);
    padding-left: var(--wrapper-mobile-padding-left);
    padding-right: var(--wrapper-mobile-padding-right);
}

.wrapperLink {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: var(--wrapper-mobile-margin-bottom);
    text-decoration: none;
}

.card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    padding: var(--card-padding);
    background: var(--card2-background);
    max-width: var(--card-mobile-width);
    overflow: hidden;
    border-radius: var(--card-border-radius);
    border: var(--card-border, none);
    box-shadow: var(--card-box-shadow);
    margin-bottom: 30px;
}

.grid1 img,
.grid2 img {
    height: 100%;
}

.image {
    max-width: var(--card-mobile-image-width);
    max-height: var(--card-mobile-image-height);
    display: flex;
    align-self: center;
}

.content {
    width: 100%;
    padding: 0 27px;
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 42px;
    overflow: hidden;
}

.content .subtitle {
    color: var(--card-subtitle-color);
    text-transform: var(--title-text-transform);
}

.title {
    margin: 0;
    color: var(--card-title-color);
    text-transform: var(--title-text-transform);
    white-space: var(--title-white-space);
}

.description {
    margin-top: 12px;
    color: var(--card-description-color);
    text-transform: var(--card-description-transform);
    font-weight: var(--card-description-font-weight);
    -webkit-line-clamp: var(--card-description-webkit-line-clamp, 2);
}

.legalMessage {
    margin: 0;
}

.title,
.subtitle,
.description {
    text-align: center;
}

.grid3 .description {
    max-width: var(--card3-description-max-width);
    margin: 0 auto;
}

.card .cta {
    display: inline-block;
    padding-top: var(--card-button-padding);
    padding-bottom: var(--card-button-padding-bottom, var(--card-button-padding));
    position: absolute;
    text-align: center;
    bottom: 30px;
    transform: translate(-50%, 50%);

    /* 100% - padding-left - padding-right - image offset */
    max-width: var(--card-button-max-width);
    letter-spacing: 0.02em;
    z-index: 1;
}

.grid3 .title {
    margin: var(--card3-mobile-title-margin);
}

.grid3 .content {
    padding: var(--card3-content-mobile-padding);
}

/* button overflow compensation */
.grid1,
.grid2,
.grid3 {
    padding-bottom: 25px;
}

.viewDetails {
    display: inline-block;
}

@media (min-width: 960px) {
    .wrapper {
        padding-left: var(--wrapper-desktop-padding-left);
        padding-right: var(--wrapper-desktop-padding-right);
        display: flex;
        justify-content: space-between;
    }

    .header {
        padding-left: var(--wrapper-desktop-padding-left);
        padding-right: var(--wrapper-desktop-padding-right);
        margin-bottom: var(--header-desktop-margin-bottom);
    }

    .card {
        box-sizing: border-box;
        max-width: 100%;
    }

    .content {
        padding: 0;
    }

    .content,
    .title,
    .subtitle,
    .description {
        text-align: left;
    }

    .image {
        max-height: 100%;
    }

    .wrapperGrid1 {
        width: 100%;
    }

    .grid1 {
        max-height: 300px;
        margin-bottom: 60px;
        padding-bottom: 0px;
    }

    .text {
        flex: 1;
    }

    .grid1 .card {
        margin-top: var(--grid-card-margin-top);
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background: var(--col--light);
        margin-bottom: 0;
    }

    .grid1 .image {
        margin-bottom: 0;
        max-width: var(--card1-desktop-image-width);
    }

    .grid1 .content {
        display: flex;
        flex-direction: column;
        padding: 0px 102px 0px 40px;
    }

    .grid1 .content.ctaAtRight {
        flex-direction: row;
        padding: 0px 40px;
    }

    /* TODO: update typography with molecule styles */
    .grid1 .title {
        font-size: var(--card1-desktop-title-font-size);
        line-height: var(--card1-desktop-title-line-height);
    }

    .grid1 .subtitle {
        font-size: var(--card1-desktop-subtitle-font-size);
        line-height: var(--card1-desktop-subtitle-line-height);
    }

    .grid1 .description {
        margin-top: 4px;
        max-width: 100%;
    }

    .grid1 .legalMessage {
        margin: 0;
    }

    .grid2,
    .grid3 {
        padding-bottom: 50px;
    }

    .grid1 .cta {
        position: static;
        margin-top: 20px;
        transform: none;
        max-width: 100%;
        align-self: flex-start;
    }

    .grid1 .ctaAtRight .cta {
        position: static;
        margin-top: 0px;
        margin-left: 20px;
        align-self: center;
        transform: none;
        max-width: 100%;
    }

    .wrapperLink {
        height: auto;
        margin-bottom: 0;
    }

    .wrapperGrid2 {
        width: 50%;
        height: auto;
        margin-bottom: 0;
    }

    .grid2 .card {
        margin-top: var(--grid-card-margin-top);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 659px;
        margin-right: 19px;
    }

    .grid2 .image {
        width: var(--card2-desktop-image-width);
        margin-bottom: 0px;
    }

    .grid2 .subtitle {
        font-size: var(--card2-subtitle-font-size);
        line-height: var(--card2-subtitle-line-height);
    }

    .grid2 .content {
        padding: var(--card2-desktop-padding);
        padding-top: var(--card2-desktop-content-padding-top);
        padding-bottom: var(--card2-desktop-content-padding-bottom);
        height: 100%;
    }

    .grid2 .description {
        margin-top: 14px;
        max-width: 308px;
    }

    .grid2 .legalMessage {
        margin: 0;
    }

    .grid2 .cta {
        display: inline-block;
        transform: translateY(50%);

        /* 50% - padding-left - padding-right - image offset */
        max-width: calc(50% - 91px);
    }

    .grid3 .cta {
        bottom: -26px;
        left: 50%;
        transform: translateX(-50%);
    }

    .wrapperGrid3 {
        width: 33%;
        max-width: var(--card3-desktop-image-max-width);
    }

    .grid3 .card {
        margin: 0;
        width: var(--card3-desktop-image-max-width);
        max-width: var(--card3-desktop-max-width);
        margin-right: var(--card3-desktop-margin-right);
    }

    .grid3 .image {
        width: var(--card3-desktop-card-width);
        max-width: var(--card3-desktop-image-width);
    }

    .grid3 .content,
    .grid3 .title,
    .grid3 .subtitle,
    .grid3 .description {
        text-align: center;
    }

    .grid3 .title {
        margin: var(--card3-desktop-title-margin);
    }

    .grid3 .content {
        padding: var(--card3-content-padding);
        max-width: var(--card3-content-max-width);
        margin: 0 auto;
    }
}

@media (min-width: 1441px) {
    .grid2 .card {
        padding-right: 57px;
    }
}
