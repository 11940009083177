.mainBanner {
    composes: mainBanner from './mainBanner.vars.css';
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 594px;
    align-items: center;
    background-size: var(--mainBanner-background-size, cover);
    background-position: var(--mainBanner-background-position, center);
}

.mainBannerWithBgVideo {
    position: relative;
    z-index: 0;
    background-color: var(--col--dark);
}

.mainBannerVerticallyCentered {
    align-items: center;
}

.actionsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 0 3.5em 2em;
    z-index: 1;
}

.imageSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: var(--mainBanner-imageSection-padding);
}

.rightImageSection {
    align-items: var(--mainBanner-rightImageSection-align-items);
    align-self: var(--mainBanner-rightImageSection-align-self);
    justify-content: var(--mainBanner-rightImageSection-justify-content);
    padding: var(--mainBanner-rightImageSection-padding);
}

.mainBannerLeftImage .actionsSection {
    padding: 2em 52px 2em 2em;
}

.mainBannerLeftImage .imageSection {
    padding: var(--mainBanner-imageSection-padding);
}

.mainBannerLeftImage .mainText {
    margin-right: 0;
}

.caloriesAndPrice {
    margin-top: -10px;
    padding-bottom: 28px;
}

.backgroundImageObjectFit {
    object-fit: contain;
}

@media screen and (min-width: 1025px) {
    .actionsSection {
        width: 50%;
        align-items: flex-start;
        padding: 30px 0 40px 60px;
    }

    .rightImageSection,
    .mainBannerLeftImage .imageSection {
        display: flex;
        align-items: var(--mainBanner-rightImageSection-align-items);
        align-self: var(--mainBanner-rightImageSection-justify-content);
        max-width: 100%;
        padding-top: 0;
        padding-left: 0;
    }

    .imageSection img {
        max-width: 100%;
        min-height: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .actionsSection {
        padding: var(--mainBanner-actionsSection-desktop-padding);
    }

    .mainBannerLeftImage .imageSection img {
        padding-left: 0px;
    }
}

@media screen and (min-width: 1441px) {
    .mainBanner {
        padding: var(--mainBanner-desktop-padding);
        margin: auto;
        max-width: var(--mainBanner-max-width);
    }

    .actionsSection {
        align-items: flex-start;
    }

    .mainBannerLeftImage .actionsSection {
        padding: 49px 52px 59px 0;
    }

    .imageSection {
        width: 50%;
    }

    .rightImageSection {
        width: var(--mainBanner-rightImageSection-width);
        justify-content: var(--mainBanner-rightImageSection-justify-content-desktop);
    }
}

.topIconBox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
}

.topIcon {
    max-height: 60px;
}

.topIconSection {
    padding-right: 16px;
}

.topIconBox .topIconText {
    color: var(--mainBanner-topIconText-color);
    text-transform: var(--mainBanner-topIconBox-topIconText-transform);
    font-weight: var(--mainBanner-topIconBox-topIconText-font-weight);
}

.topLink {
    display: block;
}

@media screen and (max-width: 1024px) {
    .topIconBox {
        align-items: center;
        justify-content: center;
        margin-top: var(--mainBanner-topIconBox-topIconText-mobile-marginTop);
    }

    .topIconText {
        margin-left: 0.1em;
    }

    .imageSection img {
        height: 200px;
        width: auto;
    }

    .rightImageSection {
        margin: var(--mainBanner-rightImageSection-mobile-margin);
        padding: var(--mainBanner-rightImageSection-mobile-padding);
    }

    .imageSection.rightImageSection {
        margin-bottom: 41px;
    }

    .imageSection.rightImageSection img,
    .mainBannerLeftImage .imageSection img {
        height: var(--mainBanner-rightImageSection-img-height);
        width: var(--mainBanner-rightImageSection-img-width);
        max-width: 100%;
        max-height: 281px;
        margin-top: 20px;
    }
}

.buttonsBlock {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.mainText {
    color: var(--mainBanner-mainText-color);
    max-width: var(--mainBanner-mainText-maxWidth);
    margin-top: var(--mainBanner-mainText-marginTop);
    margin-bottom: var(--mainBanner-mainText-marginBottom);
    margin-right: 30px;
}

.centeredContentWrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0;
    padding: 137px 270px 46px;
}

.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    text-align: center;
}

.mainLink a,
.secondaryLink a {
    display: block;
    height: 60px;
}

.secondaryLink {
    margin-left: 20px;
}

.bottomText {
    margin-top: var(--mainBanner-bottomText-marginTop);
    margin-bottom: var(--mainBanner-bottomText-marginBottom);
    max-width: var(--mainBanner-bottomText-maxWidth);
}

.bottomText * {
    display: var(--mainBanner-bottomText-display);
    color: var(--mainBanner-bottomText-color);
}

.centerContent.bottomText {
    max-width: 900px;
}

a.mainButton:link,
a.mainButton:visited {
    color: var(--col--light);
}

@media (max-width: 1024px) {
    .mainBanner {
        padding: 15px 20px 44px;
        padding: var(--mainBanner-mobile-padding);
        flex-direction: var(--mainBanner-flexDirection);
        justify-content: flex-start;
        min-height: 526px;
    }

    .mainBannerLeftImage {
        flex-direction: column;
    }

    .mainBannerNoRightImage {
        flex-direction: column;
    }

    .mainBannerLeftImage .actionsSection,
    .actionsSection {
        padding: 0;
        text-align: center;
    }

    .buttonsBlock {
        max-width: 100vw;
    }

    .topIcon {
        height: 25px;
    }

    .topIconSection {
        padding-right: 0;
    }

    .topIconText {
        letter-spacing: 0.02em;
        padding: 0 10px;
        text-transform: var(--mainBanner-topIconText-transform);
    }

    .mainText {
        margin-top: var(--mainBanner-mainText-mobile-marginTop);
        margin-right: 0;
    }

    .bottomText {
        margin-top: var(--mainBanner-bottomText-mobile-marginTop);
        margin-bottom: var(--mainBanner-bottomText-mobile-marginBottom);
        max-width: var(--mainBanner-bottomText-maxWidth);
    }

    .mainBannerLeftImage .imageSection,
    .imageSection {
        padding-right: 0;
        padding-left: 0;
    }

    .centeredContentWrapper {
        align-items: center;
        padding: 0;
        padding: 126px 20px 111px;
    }

    .centeredContent {
        min-width: 336px;
    }
}

@media (max-width: 767px) {
    .mainBannerLeftImage .actionsSection,
    .actionsSection {
        width: 100%;
        padding-right: 0;
    }

    .isDualButton.buttonsBlock {
        max-width: 100vw;
    }

    .mainLink a,
    .secondaryLink a {
        height: 50px;
    }

    .isDualButton .secondaryLink {
        margin-left: 20px;
    }

    .mainBanner .centeredContent {
        padding-left: 0px;
    }
}

.bgVideo {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

@media (min-width: 960px) {
    .mainTextUmlaut {
        line-height: var(--mainBanner-mainTextUmlaut-line-height);
    }
}

@media (max-width: 960px) {
    .mainText {
        max-width: var(--mainBanner-mainText-mobile-maxWidth);
    }
}

@media (max-width: 1440px) {
    .backgroundImageObjectFit {
        object-fit: cover;
    }
}

/* override styles for colors from CMS */
.mainBanner .wrapperTopText span,
.mainBanner .wrapperMainText h2,
.mainBanner .wrapperBottomText * {
    color: inherit;
}
