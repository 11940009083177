.wrapper {
    margin-top: 46px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    padding: 50px;
}

.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
}

.form > div {
    box-sizing: border-box;
    margin: 10px 31px 0 0;
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 31px);
}

.form > div:nth-child(2n) {
    margin-right: 0;
}

.form::after {
    content: '';
    flex: auto;
}

.form > div:nth-child(-n + 2) {
    margin-top: 0;
}

.label {
    font-size: 12px;
    line-height: 12px;
    font-family: var(--ff--pharagraph);
    margin-bottom: 8px;
}

.input {
    font-family: var(--ff--pharagraph);
    font-size: 16px;
    line-height: 24px;
    color: var(--col--dark);
    text-transform: none;
}

.input::placeholder {
    color: var(--col--dark);
}

.inputError {
    color: var(--col--utilityError);
    width: 100%;
    font-family: var(--ff--pharagraph);
    font-size: 80%;
    margin-top: 0.31em;
}

.labelStar {
    color: var(--col--utilityError);
}

div.submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 43px 0 0;
    width: 100%;
}

.dropdown {
    font-size: 16px;
}

.submitModal {
    background: var(--col--light);
    top: 372px;
    left: 50%;
    transform: translate(-50%, -186px);
    position: absolute;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 30px 30px;
    width: 530px;
    box-sizing: border-box;
}

.submitModal span.starIcon {
    font-size: 60px;
}

.submitModal h3 {
    margin-bottom: 10px;
}

.submitModal p {
    margin-bottom: 75px;
}

.submitModal .closeIcon {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
}

.error {
    padding: 28px;
    color: var(--col--light);
    background-color: var(--col--utilityError);
    position: fixed;
    z-index: 1000;
    top: 82px;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.error > span:first-child {
    margin-right: 12px;
}

@media (max-width: 960px) {
    .form {
        flex-direction: column;
        margin-top: 16px;
    }

    .form > div {
        box-sizing: border-box;
        margin: 10px 0 0;
        width: 100%;
    }

    div.submitButton {
        margin: 36px 0 0;
        width: 100%;
    }

    div.submitButton button {
        width: 100%;
        margin: 0 30px;
    }

    .wrapper {
        margin-top: 50px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
        padding: 30px 20px 35px;
    }
}

@media (max-width: 767px) {
    .submitModal {
        background: var(--col--light);
        top: 247px;
        left: 50%;
        transform: translate(-50%, -123px);
        padding: 30px 0px 0px;
        width: 100%;
    }

    .modalText {
        padding: 0 21px;
    }

    .submitModal button {
        width: 100%;
    }

    .submitModal p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 50px;
    }

    .error {
        flex-direction: column;
    }

    .error > span:first-child {
        margin-right: 0px;
        margin-bottom: 12px;
    }
}
