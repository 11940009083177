.sectionWrapper {
    margin-top: 80px;
    position: relative;
    overflow: hidden;
}

.sectionFlexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    text-align: center;
    align-items: stretch;
    padding: 0 52px;
}

.backgroundText {
    z-index: -1;
    font-family: var(--head-font);
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--col--gray2);
    font-size: 124px;
    position: absolute;
    margin-top: -73px;
    margin-left: 150px;
    white-space: nowrap;
    line-height: 124px;

    /* 100% - backgroundText.margin-left  */
    max-width: calc(100% - 150px);
}

.backgroundTile {
    position: absolute;
    width: 776px;
    height: 250px;
}

.backgroundTileColor {
    background: var(--col--dark);
}

.top {
    top: 0;
    left: 0;
}

.bottom {
    bottom: 0;
    right: 0;
}

.hidden {
    display: none;
}

@media (max-width: 1200px) {
    .backgroundTile {
        width: 100%;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .backgroundText {
        font-size: 34px;
        line-height: 34px;
        position: inherit;
        padding-left: 20px;
        padding-top: 0;
        height: 44px;
        margin: inherit;
    }

    .wrapperComponents {
        display: flex;
        flex-direction: column;
    }

    .wrapperComponents.withBackgroundText {
        margin-bottom: -12px;
    }

    .sectionFlexContainer {
        padding: 0 20px;
    }

    .bulletHeaderContainer {
        padding-bottom: 0;
    }
}

@media (max-width: 425px) {
    .backgroundTile {
        height: 159px;
    }

    .bottom {
        bottom: 10px;
        right: 0;
    }
}

/* Mobile devices */
@media (max-width: 600px) {
    .sectionWrapper {
        margin-top: 40px;
    }
}

@media (min-width: 1441px) {
    .sectionFlexContainer {
        padding: 0 292px;
    }

    .wrapperComponents {
        padding: 0 240px;
    }

    .backgroundText {
        /* 100% - backgroundText.margin-left - sectionFlexContainer.padding  */
        max-width: calc(100% - 150px - 292px);
    }
}
