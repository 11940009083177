.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-word;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.container .banner {
    width: 885px;
    word-break: break-word;
}

.formContainer {
    position: relative;
    width: 885px;
    padding: 50px 50px 60px;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: var(--col--light);
}

.successMessage {
    width: 100%;
}

.dropdown {
    margin: 0 20px 20px 0;
    width: 380px;
}

.dropdownError {
    border: 1px solid var(--col--primary1);
}

.dropdownItem {
    height: 50px;
    font-family: var(--base-font);
    font-size: 16px;
    line-height: 24px;
}

.dropdownItem::before {
    display: none;
}

:global(.MuiMenuItem-root).selectedDropdownItem {
    font-family: var(--heavy-font);
    background-color: transparent;
}

.inputContainer {
    width: 380px;
    margin-bottom: 20px;
}

.inputContainer:nth-child(2n) {
    margin-right: 20px;
}

.input {
    font-family: var(--base-font);
    font-size: 16px;
    line-height: 24px;
    color: var(--col--dark);
}

.inputError {
    font-family: var(--base-font);
    font-size: 12px;
    line-height: 12px;
    position: absolute;
    margin-top: 4px;
    color: var(--col--primary1);
}

.inputLabel {
    font-size: 12px;
    line-height: 12px;
    font-family: var(--base-font);
}

.footerText {
    color: var(--col--gray6);
    width: 100%;
}

.footerText p {
    color: var(--col--gray6);
    font-size: var(--hint-lg-size, 14px);
    font-family: var(--base-font);
}

.footerText a {
    font-family: var(--heavy-font);
    font-style: normal;
    font-weight: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
}

.legalText {
    width: 884px;
    margin-top: 65px;
    text-align: center;
    box-sizing: border-box;
}

.buttonWrapper {
    position: absolute;
    left: 0;
    bottom: -25px;
    right: 0;
    justify-content: center;
    display: flex;
}

.title {
    width: 100%;
    margin: 0 0 30px;
}

.listClassName {
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 960px) {
    .formContainer {
        width: calc(100% - 40px);
        padding: 30px 20px 60px;
    }

    .banner {
        width: 100%;
    }

    .dropdown {
        width: 100%;
        margin-right: 0;
    }

    .inputContainer {
        width: 100%;
    }

    .inputContainer:nth-child(2n) {
        margin-right: 0px;
    }

    .legalText {
        width: 100%;
        padding: 0 20px;
    }

    .title {
        margin-bottom: 22px;
    }

    .footerText p {
        font-size: var(--hint-md-size, 12px);
    }
}
