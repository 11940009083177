.legalMessage {
    font-family: var(--ff--pharagraph);
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}

.viewDetails {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 767px) {
    .legalMessage {
        text-align: center;
        z-index: 0;
        line-height: 20px;
    }
}
