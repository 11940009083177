.numberedList {
    --list-wrapper-padding: 45px;
    --description-margin-bottom: 32px;
    --description-mobile-margin-bottom: 32px;
    --item-margin-bottom: 50px;
    --item-mobile-margin-bottom: 32px;
    --item-title-margin-bottom: 8px;
    --item-title-mobile-margin-bottom: 14px;
    --item-description-padding-left: 79px;
    --item-number-margin-right: 21px;
    --item-number-font-size: 52px;
    --item-number-line-height: 52px;
}
