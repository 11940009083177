.wrapper {
    composes: wrapper from './locationsListItemLayout.vars.css';
    display: flex;
    justify-content: space-between;
    line-height: 0;
    height: 100%;
}

.leftColumn {
    min-width: var(--left-column-min-width);
    margin-right: 25px;
}

.rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.wrapper .distanceAndStoreId {
    color: var(--col--gray6);
}

.wrapper .openingHours {
    color: var(--col--gray6);
}

.title {
    margin-bottom: 5px;
}

.address {
    margin-bottom: var(--address-margin-bottom);
}

.secondaryCta {
    margin-top: 6px;
}
