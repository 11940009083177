.RecentOrdersHeader {
    --RecentOrdersHeader-background-color: none;
    --RecentOrdersHeader-image-align-self: unset;
    --RecentOrdersHeader-viewAll-color: var(--col--primary2);
    --RecentOrdersHeader-viewAll-font-weight: 700;
    --RecentOrdersHeader-viewAll-font-size: 11px;
    --RecentOrdersHeader-viewAll-line-height: 11px;
    --RecentOrdersHeader-viewAll-text-transform: uppercase;
    --RecentOrdersHeader-subheader-font-style: normal;
    --RecentOrdersHeader-subheader-font-weight: 700;
    --RecentOrdersHeader-subheader-font-size: 20px;
    --RecentOrdersHeader-subheader-line-height: 22px;
}

@media (max-width: 959px) {
    .RecentOrdersHeader {
        --RecentOrdersHeader-subheader-font-size: 16px;
        --RecentOrdersHeader-subheader-line-height: 18px;
    }
}
