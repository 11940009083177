.container {
    --sectionItem-padding: 15px 26px;
    --sectionItem-small-padding: 10px 20px;
    --item-border-left: solid 5px var(--col--light);
    --line-border-left: solid 5px var(--col--primary1);
    --collapsedTitle-color: var(--col--primary1);
    --subItemsContainer-padding: 0 26px;
    --subItemsContainer-small-padding: 0 26px;
    --subItem-small-margin-bottom: 20px;
    --subItem-padding-right: 100px;
    --subItem-small-padding-right: 30px;
    --collapsedItemSection-border-top-color: var(--col--gray4);
}
