.phoneLink {
    composes: secondaryPhoneLink from './phoneLink.vars.css';
    display: inline-flex;
    font-size: 26px;
    line-height: 26px;
    align-items: center;
}

.phoneIcon {
    padding-right: 10px;
}

.phoneLink.secondary {
    color: var(--col--dark);
    font-style: normal;
    font-weight: normal;
    font-size: var(--phone-font-size-mobile);
    line-height: var(--phone-lineheight-mobile);
}

.phoneLink.secondary .phoneIcon {
    color: var(--col--primary1);
    font-size: var(--phone-icon-font-size-mobile);
    margin-right: 5px;
    padding: 0;
}

@media screen and (min-width: 960px) {
    .phoneLink.secondary {
        font-size: var(--phone-font-size);
        line-height: var(--phone-lineheight);
    }

    .phoneLink.secondary .phoneIcon {
        font-size: var(--phone-icon-font-size);
    }
}
