.bulletHeaderContainer {
    composes: sectionHeader from './sectionHeader.vars.css';
    display: flex;
    flex-direction: var(--container-flex-direction);
    padding-bottom: 12px;
}

.headerText {
    margin: 0px;
}

/* TODO get rid of the margin-left, this component shouldn't have surrounding layout margins */
.headerTextWithoutBullet {
    margin-left: var(--header-text-without-bullet-margin-left);
}

.boxBullet {
    width: var(--box-bullet-width);
    height: var(--box-bullet-height);
    background: var(--col--primary1);
    margin: var(--box-bullet-margin);
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .boxBullet {
        width: var(--box-bullet-small-width);
        height: var(--box-bullet-small-height);
        margin-top: 18px;
        margin-right: 8px;
    }

    .bulletHeaderContainer {
        padding-bottom: 0;
    }

    .headerText {
        margin-top: var(--section-title-margin-top);
    }

    .headerTextWithoutBullet {
        margin-left: var(--header-without-bullet-margin, 20px);
    }
}

@media (min-width: 1441px) {
    .headerTextWithoutBullet {
        margin-left: var(--header-without-bullet-margin, 292px);
    }
}
