.chip {
    composes: chip from './chip.vars.css';
    font-family: var(--ff--link);
    font-size: var(--chip--font-size);
    line-height: var(--chip--line-height);
    text-transform: var(--chip-text-transform);
    color: var(--col--light);
    background-color: var(--col--dark);
    padding: var(--padding);
    font-weight: 700;
}

.clickable.outlined {
    background-color: transparent;
    border: 1px solid var(--col--dark);
    color: var(--col--dark);
}

.clickable.outlined:hover,
.clickable.outlined.selected {
    background-color: var(--col--dark);
    color: var(--col--light);
}

.label,
.labelSmall {
    padding: 0;
}

.sizeSmall {
    height: auto;
}
