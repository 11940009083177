.modal {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    composes: content from './rewardDetailsModal.vars.css';
    outline: none;
    position: relative;
    width: 100vw;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    background-color: var(--col--light);
    box-sizing: border-box;
}

.closeButton {
    position: absolute;
    right: var(--closeIcon-right);
    top: var(--closeIcon-top);
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.closeIcon::before {
    transform: rotate(45deg);
    color: var(--closeIcon-color);
}

.closeIcon::after {
    transform: rotate(-45deg);
}

.iconText {
    display: inline;
    text-decoration: underline;
    margin-right: 15px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: var(--col--dark);
}

.button {
    height: 60px;
    width: 100%;
    text-transform: uppercase;
    white-space: nowrap;
}

.header .modalTitle {
    margin: 0;
    color: var(--modalTitle-color);
    text-transform: var(--modalTitle-text-transform);
}

.header .subtitle {
    color: var(--col--primary1);
}

.header {
    padding: var(--header-padding);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: var(--header-background-color);
}

.mainContent {
    flex: 1;
    overflow-y: auto;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
}

.mainInfo {
    padding: 20px 20px 0;
}

.title {
    margin: var(--title-margin-mobile);
}

.catalogViewTitle {
    margin: 0 0 9px;
}

.dateLabel,
.date,
.terms,
.cancellationTips {
    color: var(--col--gray6);
}

.date {
    margin-top: 23px;
}

.dateLabel {
    text-transform: var(--dateLabel-text-transform);
}

.imageContainer {
    margin-top: 17px;
    display: flex;
    justify-content: center;
    max-width: 100%;
    height: 281px;
    position: relative;
}

.itemImage {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.tags {
    margin-top: 15px;
}

.terms {
    padding: var(--terms-padding);
}

.tag {
    display: inline-block;
    font-family: var(--ff--subheader-small);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: var(--col--light);
    background-color: var(--col--utilitySuccess);
    padding: 3px 10px 4px;
    border-radius: 27px;
    text-transform: uppercase;
}

.loader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoHeader {
    color: var(--col--gray6);
}

.infoList {
    padding: 0;
    margin: 0;
    counter-reset: counter;
}

.infoList > li {
    font-size: inherit;
    line-height: inherit;
    counter-increment: counter;
}

.infoList > li::before {
    content: counter(counter) '. ';
    color: var(--col--gray6);
}

.infoText {
    margin-top: 30px;
}

.displayPoints {
    font-family: var(--ff--pharagraph);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--col--utilitySuccess);
}

.errorDisplayPoints {
    color: var(--col--utilityError);
}

.cancellationStatus {
    color: var(--col--utilitySuccess);
    text-transform: uppercase;
}

.cancellationTips {
    margin-top: 23px;
}

.disabled {
    user-select: none;
    pointer-events: none;
}

.buttonsBlock {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    padding: var(--buttonsBlock-padding);
}

.buttonsBlockWithHint {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.buttonsErrorHint {
    color: var(--col--primary1);
    padding-top: 10px;
    width: 100%;
    text-align: left;
}

.successErrorModalRowButtonStyleOverride {
    bottom: 0px !important;
}

.successErrorModalButtonStyleOverride {
    width: 100% !important;
}

@media (max-width: 960px) {
    .successErrorModalMobileViewStyleOverride {
        height: 318px !important;
    }

    .iconText {
        display: none;
    }
}

@media (min-width: 960px) {
    .content {
        border-radius: var(--content-border-radius);
        overflow-x: hidden;
        width: 1109px;
        max-height: var(--content-max-height);
        overflow-y: visible;
        box-shadow: 0px 4px 10px rgba(56, 46, 44, 0.1);
    }

    .closeButton {
        right: 24px;
        top: 24px;
    }

    .title {
        margin: 0 0 15px;
    }

    .buttonsBlock {
        padding: var(--buttonsBlock-padding);
        justify-content: flex-end;
        background-color: var(--buttonsBlock-background-color);
    }

    .button {
        margin-bottom: 0;
        height: 50px;
        width: auto;
        padding: 16px 30px;
    }

    .header {
        padding: var(--header-padding);
    }

    .description {
        margin-top: 16px;
    }

    .date {
        margin-top: 7px;
    }

    .imageContainer {
        position: absolute;
        left: 40px;
        top: 40px;
        margin: 0;
        display: flex;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        height: 300px;
    }

    .itemImage {
        width: 100%;
    }

    .mainContent {
        position: relative;
        padding: var(--mainContent-padding);
        display: flex;
        justify-content: flex-end;
    }

    .contentWrapper {
        width: 502px;
    }

    .mainInfo,
    .terms {
        padding: 0;
    }

    .terms {
        margin-top: var(--terms-margin-top);
        max-width: var(--terms-max-width);
    }

    .displayPoints {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

    .catalogViewTitle {
        margin: 0 0 15px;
    }

    .buttonsBlockWithHint {
        flex-direction: var(--button-block-hint-flex-direction);
        width: initial;
    }

    .buttonsErrorHint {
        max-width: 166px;
        text-align: center;
    }
}
