.sectionLayout {
    composes: sectionLayout from './infoBlockSection.vars.css';
    background-size: var(--sectionLayout-background-size);
    margin-top: var(--sectionLayout-margin-top);
}

.sectionFlexContainer {
    composes: infoBlockSection from './infoBlockSection.vars.css';
    display: flex;
    justify-content: center;

    /* gap: 20px; gap doesn't work on iOS/macOS devices, replaced with margins */
    text-align: center;
    align-items: stretch;
    width: 100%;
}

.sectionMoreThree {
    flex-wrap: wrap;
}

.card {
    box-sizing: border-box;
    padding: var(--mobile-card-padding);
    height: 246px;
    color: var(--col-light);
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 100%;
}

.double {
    width: calc(50% - 10px);
}

.triple {
    width: calc(33.33% - 14px);
}

.moreThree {
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
}

.card img {
    width: 60px;
    height: 60px;
}

.content {
    max-width: 100%;
}

.cardWithButton {
    /* .buttonSection has position: absolute and bottom: -25px; */

    /* .card margin 10px + button offset 25px + shadows space 25px = 60px */
    margin-bottom: 60px;
}

.cardHeader {
    text-align: center;
    margin: var(--card-header-margin);
}

.cardHeader:empty {
    display: none;
}

.cardSubheader {
    text-align: center;
    margin-bottom: var(--card-sub-header-margin-bottom);
}

.cardSubheader:empty {
    display: none;
}

.legalMessage {
    color: inherit;
    display: inline-block;
}

.buttonSection {
    position: absolute;
    left: 0;
    bottom: -25px;
    right: 0;
    justify-content: center;
    display: flex;
}

.infoBlockContainer {
    margin-right: var(--container-margin-right);
}

.card + .single {
    margin-right: 0px;
}

.imageBackgroundContainer {
    border-radius: var(--card-border-radius);
    box-shadow: 0px 0px 4px 0px #00000026;
}

@media (max-width: 768px) {
    .sectionLayout {
        background-size: var(--sectionLayout-small-background-size);
        margin-top: var(--sectionLayout-small-margin-top);
    }

    .card {
        width: 100%;
        height: 209px;
        flex-basis: initial;
        max-width: var(--mobile-infoBlockContainer-max-width);
        margin: 0 auto 30px;
    }

    .sectionFlexContainer {
        flex-direction: column;
        flex-wrap: wrap;
        margin: 13px 0 0;
    }

    .sectionUntitled {
        margin: 0;
    }

    .cardWithButton {
        margin-bottom: 30px;
    }

    .cardWithButton:last-child {
        margin-bottom: 60px;
    }

    .single {
        height: auto;
    }

    .single .content {
        max-width: 100%;
    }

    .card img {
        width: var(--mobile-icon-size);
        height: var(--mobile-icon-size);
    }

    .infoBlockContainer {
        max-width: 335px;
        margin: 0 auto;
    }

    .imageBackgroundContainer {
        height: calc(100% - 25px);
    }

    .triple {
        width: 100%;
    }

    .moreThree {
        width: 100%;
        margin-bottom: 0px;
    }

    .content {
        margin-bottom: var(--mobile-card-content-margin-bottom);
    }
}

@media (min-width: 768px) {
    .sectionFlexContainer {
        margin: 31px 0 0;
        width: 100%;
        max-width: 1336px;
    }

    .sectionUntitled {
        margin: 0;
    }

    .single {
        padding: var(--card-single-wrapper-padding);
        margin-top: 0;
        flex-direction: row;
        justify-content: space-between;
        height: auto;
        max-width: 100%;
        box-sizing: border-box;
        font-size: 40px;
        line-height: 40px;
    }

    .single img {
        width: 76px;
        height: 76px;
    }

    .single .cardHeader,
    .single .cardSubheader {
        text-align: left;
    }

    .single .cardHeader {
        margin: 0;
        font-size: 40px;
        line-height: 40px;
        padding-bottom: var(--card-single-padding-bottom);
    }

    .single .cardSubheader {
        margin: 0;
    }

    .single .content {
        box-sizing: border-box;
        padding: var(--card-single-content-padding);
        flex-grow: 1;

        /* 100% - buttonSection max-width */
        max-width: calc(100% - 260px);
    }

    .single .contentWithoutButton {
        max-width: 100%;
    }

    .single .buttonSection {
        position: static;
        max-width: 260px;
    }

    .single .button {
        display: block;
        width: 100%;
        justify-content: flex-start;
    }

    .single div.button {
        display: inherit;
    }

    .twice {
        max-width: 658px;
    }

    .triple {
        max-width: 432px;
    }
}
