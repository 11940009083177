.wrapper {
    --left-column-min-width: 230px;
    --address-margin-bottom: 6px;
}

@media (max-width: 768px) {
    .wrapper {
        --left-column-min-width: 204px;
    }
}
