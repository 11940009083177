.mainBanner {
    /* min-width: 1441px */
    --mainBanner-desktop-padding: 0 240px;
    --mainBanner-mobile-padding: 15px 20px 44px;
    --mainBanner-max-width: 100%;
    --mainBanner-flexDirection: column-reverse;
    --mainBanner-rightImageSection-align-items: center;
    --mainBanner-rightImageSection-justify-content: center;
    --mainBanner-rightImageSection-justify-content-desktop: center;
    --mainBanner-rightImageSection-padding: 0.5em 50px 0 0.5em;
    --mainBanner-rightImageSection-width: 50%;
    --mainBanner-rightImageSection-align-self: center;
    --mainBanner-rightImageSection-img-height: 200px;
    --mainBanner-rightImageSection-img-width: auto;
    --mainBanner-rightImageSection-mobile-margin: 0;
    --mainBanner-rightImageSection-mobile-padding: 0;
    --mainBanner-topIconText-color: var(--col--primary1);
    --mainBanner-mainText-maxWidth: 496px;
    --mainBanner-mainText-mobile-maxWidth: 496px;
    --mainBanner-mainText-color: var(--col--dark);
    --mainBanner-mainText-mobile-font-size: 34px;
    --mainBanner-mainText-mobile-line-height: 34px;
    --mainBanner-mainText-marginTop: 0px;
    --mainBanner-mainText-mobile-marginTop: 14px;
    --mainBanner-mainText-marginBottom: 0px;
    --mainBanner-mainTextUmlaut-line-height: 74px;
    --mainBanner-bottomText-marginTop: 14px;
    --mainBanner-bottomText-mobile-marginTop: 14px;
    --mainBanner-bottomText-marginBottom: 23px;
    --mainBanner-bottomText-mobile-marginBottom: 35px;
    --mainBanner-bottomText-maxWidth: 479px;
    --mainBanner-bottomText-color: var(--col--dark);
    --mainBanner-bottomText-line-height: inherit;
    --mainBanner-bottomText-display: inline;
    --mainBanner-bottomText-mobile-font-size: 16px;
    --mainBanner-bottomText-mobile-line-height: 24px;
    --mainBanner-topIconText-transform: uppercase;
    --mainBanner-topIconBox-topIconText-transform: none;
    --mainBanner-topIconBox-topIconText-font-weight: 700;
    --mainBanner-topIconBox-topIconText-mobile-marginTop: 22px;
    --mainBanner-imageSection-padding: 0.5em 50px 0 0.5em;
    --mainBanner-actionsSection-desktop-padding: 49px 0 59px 156px;
}
