.wrapper {
    display: flex;
    flex-direction: column;
    width: 77%;
    height: 411px;
    min-height: 411px;
}

.container {
    background-color: var(--col--light);
    padding: 24px 24px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    border-bottom: 5px solid var(--col--primary1);
    position: relative;
    height: 100%;
    width: 100%;
}

.modal {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    align-self: flex-end;
    margin-bottom: 12px;
    cursor: pointer;
}

.subHeader {
    color: var(--col--primary1);
}

.header {
    font-size: 52px;
    line-height: 52px;
    margin: 0 0 36px;
}

.name {
    margin-bottom: 8px;
}

.address {
    margin-bottom: 8px;
}

.phone {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.phoneIcon {
    margin-right: 10px;
    font-size: 16px;
}

.chicken {
    color: var(--col--primary1);
    position: absolute;
    bottom: -5px;
}

.phoneButton {
    display: none;
}

@media (max-width: 960px) {
    .wrapper {
        height: 100%;
        width: 100%;
        min-height: 100%;
    }

    .container {
        height: 100%;
        width: 100%;
        padding: 20px 20px 0;
        border: none;
    }

    .closeIcon {
        margin-bottom: 11px;
    }

    .header {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 28px;
    }

    .name {
        margin-bottom: 12px;
    }

    .address {
        margin-bottom: 12px;
    }

    .chicken {
        display: none;
    }

    .phoneButton {
        display: flex;
        height: 60px;
        font-size: 18px;
        line-height: 26px;
    }
}
