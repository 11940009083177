.section {
    margin: 40px 52px 0;
}

.section h1,
.section h3 {
    margin: 0;
}

.content {
    padding-top: 31px;
    display: flex;
    align-items: center;
}

.textSection {
    background: var(--col--light);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    margin-right: -94px;
    z-index: 1;
    padding: 40px;
    overflow-wrap: break-word;
    word-break: break-word;
}

.infoBlock {
    padding: 30px 0;
}

.infoBlock div:first-child {
    padding-bottom: 12px;
}

.contentHead {
    font-size: 26px;
    line-height: 26px;
}

.contentDescription {
    padding-top: 10px;
    font-family: var(--ff--pharagraph);
}

.textBlock {
    font-size: 16px;
    line-height: 18px;
    padding-right: 15px;
    text-transform: capitalize;
}

.textBlockValue {
    font-family: var(--ff--pharagraph);
    font-size: 16px;
    line-height: 18px;
}

.phoneBlock {
    padding-left: 30px;
    display: flex;
    font-size: 12px;
}

.phoneNumber {
    font-size: 20px;
    line-height: 22px;
    color: var(--col--dark);
    font-family: var(--ff--pharagraph-strong);
}

.icon {
    font-size: 18px;
}

.icon::before {
    color: var(--col--dark);
}

.bottomBlock {
    display: flex;
    align-items: center;
}

.imageLeftAligned .content {
    flex-direction: row-reverse;
}

.imageLeftAligned .textSection {
    margin-right: 0;
    margin-left: -94px;
}

.header {
    text-transform: capitalize;
}

@media (max-width: 1024px) {
    .section {
        margin: 20px 0 0;
    }

    .header {
        padding: 0 20px;
    }

    .content,
    .imageLeftAligned .content {
        padding-top: 26px;
        flex-direction: column-reverse;
    }

    /* https://stackoverflow.com/questions/44250526/images-height-bug-in-ios/63597497 */
    .imageSection img {
        width: 100%;
        object-fit: contain;
        height: auto;
        align-self: flex-start;
    }

    .textSection,
    .imageLeftAligned .textSection {
        margin: -40px 20px 0;
        padding: 30px;
    }

    .bottomBlock {
        flex-direction: column;
    }

    .phoneBlock {
        padding: 30px 0 0;
    }
}
