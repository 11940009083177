.RecentOrderItem {
    --recentOrderItem-container-width: 329px;
    --recentOrderItem-container-height: 142px;
    --recentOrderItem-container-mobile-height: 142px;
    --recentOrderItem-container-border-radius: none;
    --recentOrderItem-container-border: 1px solid var(--col--gray4);
    --recentOrderItem-orderContent-display: flex;
    --recentOrderItem-orderContent-margin-bottom: 12px;
    --recentOrderItem-orderContent-height: unset;
    --recentOrderItem-infoBlock-line-height: 18px;
    --recentOrderItem-date-margin-right: 11px;
    --recentOrderItem-date-padding: 3px 10px;
    --recentOrderItem-date-color: var(--col--light);
    --recentOrderItem-date-font-size: 12px;
    --recentOrderItem-date-font-weight: 700;
    --recentOrderItem-date-line-height: 18px;
    --recentOrderItem-date-text-transform: uppercase;
    --recentOrderItem-date-border-radius: 0;
    --recentOrderItem-date-font-family: var(--base-font);
    --recentOrderItem-orderBlock-color: var(--col--dark);
    --recentOrderItem-unavailableItems-font-size: 14px;
}

@media (max-width: 767px) {
    .RecentOrderItem {
        --recentOrderItem-unavailableItems-font-size: 12px;
    }
}
