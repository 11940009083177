.sectionHeader {
    --container-flex-direction: row;
    --box-bullet-width: 38px;
    --box-bullet-height: 19px;
    --box-bullet-margin: 8px 10px 0 0;
    --header-text-without-bullet-margin-left: 52px;

    /* small */
    --box-bullet-small-width: 13px;
    --box-bullet-small-height: 10px;
    --section-title-margin-top: 10px;
    --header-font-size-and-line-height: 36px;
}
