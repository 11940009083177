.container {
    composes: RecentOrdersHeader from './recentOrdersHeader.vars.css';
    display: flex;
    margin-bottom: 29px;
    padding: 0 21px;
}

.image {
    display: none;
    align-self: var(--RecentOrdersHeader-image-align-self);
}

.textWithCTABlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
}

.textBlock {
    display: flex;
    flex-direction: column;
}

.subheader {
    color: var(--col--secondary1);
    font-style: var(--RecentOrdersHeader-subheader-font-style);
    font-weight: var(--RecentOrdersHeader-subheader-font-weight);
    font-size: var(--RecentOrdersHeader-subheader-font-size);
    line-height: var(--RecentOrdersHeader-subheader-line-height);
}

.viewAll {
    color: var(--RecentOrdersHeader-viewAll-color);
    font-weight: var(--RecentOrdersHeader-viewAll-font-weight);
    font-size: var(--RecentOrdersHeader-viewAll-font-size);
    line-height: var(--RecentOrdersHeader-viewAll-line-height);
    text-transform: var(--RecentOrdersHeader-viewAll-text-transform);
}

@media (min-width: 768px) {
    .container {
        align-self: center;
        width: auto;
        flex-shrink: 0;
        margin-bottom: 0;
    }

    .image {
        display: block;
        height: 60px;
        width: 60px;
        background-color: var(--RecentOrdersHeader-background-color);
        margin-right: 16px;
    }

    .textWithCTABlock {
        display: block;
    }
}
