.benefitContainer {
    display: flex;
    width: 100%;
    padding: 15px;
    height: 90px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    flex-direction: row;
}

.benefitContainer:last-child {
    margin-bottom: 0;
}

.imageContainer {
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: var(--col--primary1);
    justify-content: center;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 28px;
}

.benefitTitle {
    display: inline-flex;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 2px;
    text-transform: capitalize;
}

@media screen and (min-width: 1440px) {
    .benefitContainer {
        width: 658px;
    }
}

@media (max-width: 425px) {
    .benefitContainer {
        padding: 6px 10px 12px 16px;
    }

    .textContainer {
        margin-left: 15px;
    }

    .benefitTitle {
        line-height: 16px;
    }
}
