.containerSection {
    margin-top: 0;
    padding: 0;
    width: 100%;
}

.wrapper {
    height: fit-content;
    border-bottom: 1px solid var(--col--gray4);
    width: 100%;
}

*:not(.wrapper) + .wrapper {
    border-top: 1px solid var(--col--gray4);
}

.wrapper:first-child {
    border-top: 1px solid var(--col--gray4);
}

.subHeader {
    padding: 34px 39px 50px 51px;
}

.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 39px 0 51px;
}

.accordion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 0;
    padding: 27px 0;
}

.arrowIcon {
    max-width: 24px;
    margin-left: 7px;
    flex: 1 0 auto;
}

.expanded {
    display: block;
}

.collapsed {
    display: none;
}

@media (max-width: 960px) {
    .container {
        flex-direction: column;
    }

    .accordion {
        padding: 22px 0;
    }

    .subHeader {
        padding: 12px 30px 32px;
    }
}

@media (max-width: 600px) {
    .header {
        padding: 0;
    }

    .container {
        padding: 0 30px;
    }
}
