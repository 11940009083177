.title {
    --title-margin-bottom: 26px;
    --title-margin-right: 20px;
    --title-padding: 0 0 12px 0;
    --title-small-padding: 0 0 12px 0;
}

.container {
    --container-mobile-margin: 22px auto 0 auto;
}
