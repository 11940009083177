.container {
    composes: container from './withAccordion.vars.css';
    padding: 0 52px;
}

.title {
    margin-bottom: 26px;
}

.item {
    border-left: var(--item-border-left);
    transition: border-left 0.2s;
}

.item:last-child {
    border-bottom: solid 1px var(--col--gray4);
}

.itemSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    border-top: solid 1px var(--col--gray4);
    padding: var(--sectionItem-padding);
    box-sizing: border-box;
    cursor: pointer;
}

.collapsedItemSection {
    border-top: solid 1px var(--collapsedItemSection-border-top-color);
}

.itemSection > .heading {
    padding-right: 10px;
}

.line {
    border-left: var(--line-border-left);
}

.collapsedTitle {
    color: var(--collapsedTitle-color);
}

.subItemsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: var(--subItemsContainer-padding);
    box-sizing: border-box;
}

.subItem {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 40px;
    padding-right: var(--subItem-padding-right);
    box-sizing: border-box;
}

@media (max-width: 960px) {
    .itemSection {
        min-height: 64px;
        padding: var(--sectionItem-small-padding);
    }

    .subItemsContainer {
        flex-wrap: nowrap;
        flex-direction: column;
        padding: var(--subItemsContainer-small-padding);
    }

    .subItem {
        width: 100%;
        margin-bottom: var(--subItem-small-margin-bottom);
        padding-right: var(--subItem-small-padding-right);
    }

    .container {
        padding: 0 20px;
    }
}

.answer a {
    text-decoration: underline;
}
