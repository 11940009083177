.modal {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    outline: none;
    position: relative;
    background-color: var(--col--light);
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.textBlock {
    text-align: center;
    padding: 115px 21px;
}

.closeIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.closeIcon::before {
    transform: rotate(45deg);
    color: var(--col--dark);
}

.closeIcon::after {
    transform: rotate(-45deg);
}

.header {
    margin-bottom: 10px;
}

.button {
    width: 100%;
    height: 60px;
}

@media (min-width: 960px) {
    .content {
        width: 1117px;
        height: 432px;
        align-items: center;
        padding: 125px 64px 30px;
    }

    .textBlock {
        padding: 0;
    }

    .closeIcon {
        right: 24px;
        top: 24px;
    }

    .button {
        margin-bottom: 0;
        width: 101px;
        height: 50px;
    }
}
