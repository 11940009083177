.scrollerContainer {
    display: flex;
    align-items: center;
    position: relative;
}

.wrapper {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.scrollerList {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    will-change: scroll-position;
    box-sizing: border-box;
    min-width: 100%;
    float: left;
}

@media (max-width: 767px) {
    .wrapper {
        overflow-x: auto;
    }
}

@media (max-width: 767px) {
    /* use native scroll for mobile devices */
    .prevButton,
    .nextButton {
        display: none;
    }
}

.button {
    background: var(--col--primary1);
    padding: 0;
    z-index: 1;
    border: 1px solid var(--col--light);
    visibility: hidden;
    position: absolute;
}

.button:hover {
    background: var(--col--primary1variant);
}

.buttonVisible {
    visibility: visible;
}

.prevButton {
    left: -22px;
}

.nextButton {
    right: -22px;
}

.prevIcon {
    font-size: 2.5rem;
    color: var(--col--light);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    z-index: 100;
    background-color: var(--col--gray1);
}
