.container {
    composes: container from './index.vars.css';
    margin: 60px auto 0px;
}

.title {
    composes: title from './index.vars.css';
    margin-bottom: var(--title-margin-bottom);
    margin-right: var(--title-margin-right);
    padding: var(--title-padding);
}

@media (max-width: 960px) {
    .title {
        padding: var(--title-small-padding);
    }

    .container {
        margin: var(--container-mobile-margin);
    }
}
