.eventCard {
    box-sizing: border-box;
    width: 319px;
    max-height: 140px;
    padding: 15px 15px 19px;
    margin-right: 20px;
    border-radius: 6px;
}

.eventCard:last-child {
    margin-right: 0px;
}

.eventHeader {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}

.eventIcon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.eventDescription {
    height: 40px;
    margin-bottom: 13px;
}

.legalMessage {
    display: inline;
    font-size: inherit;
    line-height: inherit;
}

.eventLink {
    display: inline-block;
    font-family: var(--ff--pharagraph-strong);
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-decoration: underline;
    max-width: calc(100% - 15px);
}

@media (max-width: 767px) {
    .eventCard {
        max-height: 136px;
    }

    .eventDescription {
        height: 36px;
    }
}
