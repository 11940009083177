.markup {
    margin: 40px 57px 0;
}

.markup h1 {
    font-size: 52px;
    line-height: 52px;
}

.markup h3 {
    font-size: 26px;
    line-height: 26px;
}

.markup a {
    font-family: inherit;
    text-decoration: underline;
}

.markup a:hover {
    text-decoration: none;
}

.markup b {
    font-family: var(--ff--pharagraph-strong);
}

/** For now Arbys uses --base-font (Helvetica Neue) instead of
--ff--pharagraph (Helvetica) for paragraphs in RichText */
.markup p {
    font-family: var(--ff--pharagraph);
    font-weight: 400;
}

@media (max-width: 768px) {
    .markup {
        margin: 0 20px;
    }
}
