.wrapper {
    margin-top: 60px;
}

/* Mobile devices */
@media (max-width: 600px) {
    .wrapper {
        margin-top: 22px;
    }
}

@media (min-width: 1441px) {
    .wrapper {
        width: 100%;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        padding: 0;
    }
}
