.locationTilesWrapper {
    /* TODO: change grid to flex */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.tileWrapper {
    border: 1px solid var(--col--gray4);
}

.header {
    padding: 60px 0 30px;
}

.wrapper {
    margin: 0 56px;
}

@media (max-width: 1280px) {
    .locationTilesWrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 960px) {
    .locationTilesWrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .tileWrapper {
        border: none;
        border-top: 1px solid var(--col--gray4);
    }

    .tileWrapper:last-of-type {
        border-bottom: 1px solid var(--col--gray4);
    }

    .header {
        padding: 30px 0 20px;
    }

    .wrapper {
        margin: 0 5px;
    }
}
