.tabs {
    opacity: 1;
}

.tabIndicator {
    background-color: var(--col--primary1);
    height: 4px;
}

.disabled {
    background: transparent;
}

.tab {
    color: var(--col--dark);
}

.inactiveTab {
    opacity: 1;
}

.tabWrapper.withIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tabWrapper.withIcon > *:first-child {
    margin-bottom: 0;
    margin-right: 12px;
}
