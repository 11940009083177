.container {
    --left-border-color: var(--col--primary1);
    --bottom-border-color: var(--col--gray4);
    --background-color: var(--col--gray1);
    --padding: 20px 25px 25px 25px;
    --services-list-color: var(--col--dark);
    --location-view-order-button-font-size: 14px;
    --location-view-order-button-min-width: inherit;
    --location-no-items-text-min-width: 120px;
    --detailsPageLink-text-transform: capitalize;
    --detailsPageLink-font-size: 20px;
    --detailsPageLink-line-height: 24px;
    --selected-before-border-left: 5px solid var(--left-border-color);
    --container-selected-background-color: var(--col--light);
    --address-text-transform: uppercase;
    --title-wrapper-display: block;
    --title-wrapper-margin-bottom: inherit;
    --distance-width: inherit;
    --distance-text-align: inherit;
    --distance-margin-left: inherit;
}

@media (max-width: 768px) {
    .container {
        --padding: 25px 20px;
        --detailsPageLink-font-size: 18px;
    }
}
