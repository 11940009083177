.infoBlockSection {
    --card-border-radius: 0px;
    --card-header-margin: 20px 0 5px;
    --card-sub-header-margin-bottom: 0px;
    --card-single-wrapper-padding: 53px 77px 53px 61px;
    --card-single-content-padding: 0 51px 0 57px;
    --card-single-padding-bottom: 15px;
    --background-container-height: 246px;
    --container-margin-right: 20px;
    --mobile-infoBlockContainer-max-width: 335px;
    --mobile-icon-size: 44px;
    --mobile-card-padding: 30px;
    --mobile-card-content-margin-bottom: 0px;
}

.sectionLayout {
    --sectionLayout-margin-top: 80px;
    --sectionLayout-small-margin-top: 40px;
    --sectionLayout-background-size: unset;
    --sectionLayout-small-background-size: unset;
}
