.wrapper {
    composes: wrapper from './productItemPriceAndCalories.vars.css';
    display: flex;
    align-items: center;
    text-transform: var(--text-transform--value);
}

.oldPrice {
    text-decoration: line-through;
}

.discountedPrice {
    color: var(--priceWithDiscount-discounted-price-color);
    font-weight: var(--priceWithDiscount-discounted-price-font-weight);
}

.priceWithDiscount {
    display: flex;
    flex-direction: var(--priceWithDiscount-flex-row-direction);
}

.bottomText {
    margin-top: 9px;
    width: 100%;
}
