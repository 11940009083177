.wrapper {
    padding: 6px 52px 24px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.columnAligned {
    flex-flow: column;
    text-align: center;
}

.columnAligned .mainText {
    margin-right: 0;
}

.columnAligned .descriptionBlock {
    margin-right: 0;
}

.hasBorder {
    border-top: 1px solid var(--col--gray4);
    border-bottom: 1px solid var(--col--gray4);
}

.secondaryButton {
    white-space: nowrap;
}

.description p {
    font-size: 14px;
    line-height: 18px;
}

.mainText {
    margin-right: 57px;
    color: var(--col--primary1);
    text-align: center;
}

.descriptionBlock {
    font-family: var(--base-font);
    margin-right: 30px;
    flex: 2 1 50%;
}

.descriptionText p {
    color: inherit;
}

.icon {
    margin-right: 18px;
}

.icon,
.mainText,
.descriptionBlock,
.secondaryButton {
    margin-top: 18px;
}

@media (max-width: 960px) {
    .wrapper {
        flex-direction: column;
        padding: 40px 20px 60px;
    }

    .descriptionBlock {
        text-align: center;
        margin-bottom: 13px;
    }

    .description p {
        font-size: 12px;
    }

    .icon {
        margin-bottom: 12px;
        align-self: center;
    }

    .icon,
    .mainText,
    .descriptionBlock,
    .secondaryButton {
        margin-top: 0;
        margin-right: 0;
    }

    .mainText {
        margin-bottom: 10px;
        text-align: center;
    }

    .title {
        margin-bottom: 6px;
    }
}

@media (min-width: 1441px) {
    .wrapper {
        padding: 6px 292px 24px;
    }
}
