.wrapper {
    composes: wrapper from './index.vars.css';
    position: relative;
}

.popper {
    width: 100%;
    z-index: 1000;
}

.paper {
    max-height: var(--paper-max-height);
    width: 100%;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border: 1px solid var(--col--gray4);
    border-radius: 0;
    box-sizing: border-box;
}

.button {
    height: 50px;
    border: 1px solid var(--button-border-color);
    border-radius: var(--button-border-radius);
    display: flex;
    justify-content: space-between;
    padding: 13px 20px;
    box-sizing: border-box;
    color: var(--col--dark);
    font-size: 16px;
    padding-left: 20px;
    width: 100%;
}

.buttonText {
    padding-right: 10px;
}

.button:hover {
    background-color: var(--col--gray2);
    color: var(--col--dark);
}

.button:focus {
    border: 2px solid var(--col--dark);
    color: var(--col--dark);
}

.button:disabled {
    border: 1px solid var(--col--gray4);
    background-color: var(--col--gray1);
    color: var(--col--gray5);
}

.active {
    color: var(--col--dark);
}

.label {
    font-size: 12px;
    line-height: 12px;
    font-family: var(--base-font);
    display: inline-block;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

@media screen and (max-width: 960px) {
    .paper {
        max-height: var(--paper-mobile-max-height);
    }
}
