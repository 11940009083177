.container {
    composes: container from './locationsListItem.vars.css';
    position: relative;
    padding: var(--padding);
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid var(--bottom-border-color);
    background-color: var(--background-color);
    height: 100%;
}

.container:last-of-type {
    border: none;
}

.selected::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    height: 100%;
    border-left: var(--selected-before-border-left);
}

.container.selected {
    background-color: var(--container-selected-background-color);
}

.title {
    display: inline;
    margin: 0;
}

.title.withLink {
    text-decoration: none;
}

.titleFontSize {
    font-size: 22px;
    line-height: 24px;
}

.detailsPageLink {
    composes: t-subheader-smaller from global;
    text-transform: var(--detailsPageLink-text-transform);
    text-decoration: underline;
    font-family: var(--ff--subheader-small);
    font-size: var(--detailsPageLink-font-size);
    line-height: var(--detailsPageLink-line-height);
}

.address {
    text-transform: var(--address-text-transform);
    color: var(--col--dark);
    white-space: pre-wrap;
}

.noItemsText {
    width: auto;
    font-size: var(--location-view-order-button-font-size);
    min-width: var(--location-no-items-text-min-width);
    min-height: 44px;
    line-height: 22px;
    text-align: center;
    color: var(--col--primary1);
}

.filledButton {
    white-space: nowrap;
    width: auto;
    font-size: var(--location-view-order-button-font-size);
    min-width: var(--location-view-order-button-min-width);
}

.outlinedButton {
    white-space: nowrap;
    width: auto;
    font-size: var(--location-view-order-button-font-size);
    min-width: var(--location-view-order-button-min-width);
}

.viewDealsButton {
    font-size: 12px;
    line-height: 12px;
}

.openingHours,
.distance,
.storeId {
    composes: t-paragraph-hint from global;
    color: var(--col--gray6);
}

.servicesList {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;
    composes: t-paragraph-hint from global;
    color: var(--services-list-color);

    /* font in comp is 12px in both viewports */
    font-size: 12px;
    line-height: 18px;
}

.serviceInfo {
    width: 50%;
    margin: 5px 0;
}

.unavailableOpeningHours {
    color: var(--col--primary1);
}

.openingHours.highlight {
    color: var(--status-highlight-color, var(--col--gray6));
}

.titleWrapper {
    display: var(--title-wrapper-display);
    margin-bottom: var(--title-wrapper-margin-bottom);
}

.marker {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin-right: 6px;
}

.markerIcon {
    width: auto;
    height: 100%;
}

.selected .marker {
    color: var(--col--primary1);
}

.marker .listNumber {
    position: absolute;
    display: block;
    color: var(--col--light);
}

.distance {
    width: var(--distance-width);
    text-align: var(--distance-text-align);
    margin-left: var(--distance-margin-left);
}

.serviceInfoTypes {
    width: 100%;
    margin: 5px 0;
}

.secondaryCtaButtonLayout {
    width: 50%;
    text-align: initial;
}

.myStoreWrapper {
    display: flex;
}

@media (max-width: 959px) {
    .title {
        /* design typography have equal font-size and line-height which looks ugly when text is wrapping  */
        line-height: 1.25em;
    }
}
