.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.header {
    text-align: center;
    margin: 25px 0 10px;
}

.text {
    text-align: center;
}

@media (max-width: 960px) {
    .header {
        margin: 25px 0 5px;
    }
}
