.content {
    display: flex;
    padding: 28px 0 53px;
    overflow: hidden;
    width: 100%;
}

.imageLeftAligned .content {
    flex-direction: row-reverse;
}

.imageSection {
    max-width: 375px;
    padding-left: 30px;
    padding-right: 0;
}

.imageLeftAligned .imageSection {
    padding-left: 0;
    padding-right: 30px;
}

.textSection {
    padding: 18px 30px 25px 0;
    text-align: left;
}

.sectionLayout {
    margin-top: 80px;
    padding: 0;
}

@media (max-width: 768px) {
    .sectionLayout {
        margin-top: 12px;
        padding: 0;
    }

    .header div {
        justify-content: center;
    }

    .content {
        border-top: 1px solid var(--col--gray4);
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        padding: 20px 0;
    }

    .imageLeftAligned .content {
        flex-direction: column-reverse;
    }

    .imageSection {
        padding: 0;
    }

    .imageLeftAligned .imageSection {
        padding: 0;
    }

    .textSection {
        padding: 0 0 20px;
        max-width: 335px;
    }

    .imageLeftAligned .textSection {
        padding: 20px 0 0;
    }
}
