.actionsContainer {
    display: flex;
    align-items: center;
}

.allDealsLink {
    margin: 0 0 0 30px;
}

.image {
    max-width: 650px;
}

@media screen and (max-width: 1024px) {
    .actionsContainer {
        flex-direction: column;
    }

    .allDealsLink {
        margin: 25px 0 0;
    }
}
