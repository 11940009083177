.formContainer {
    composes: container from './giftCardBalance.vars.css';
    display: flex;
    padding: 0 52px;
}

.resultContainer {
    min-height: 237px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 52px;
    padding: 25px;
    box-sizing: border-box;
}

.resultContainerHide,
.formContainerHide {
    display: none;
}

div.inputContainer {
    width: initial;
    margin-bottom: 0;
}

input.input {
    width: 335px;
    margin-right: 20px;
    display: inline-block;
}

label.inputLabel {
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--col--gray6);
}

.error {
    color: var(--col--primary1);
}

.button {
    display: inline-block;
    margin-top: 28px;
    height: fit-content;
    font-family: var(--form-button-font-family);
    font-size: var(--form-button-font-size);
    font-weight: var(--form-button-font-weight);
}

.head {
    color: var(--col--primary1);
}

.result {
    text-align: center;
}

.description {
    padding-top: 18px;
    font-size: 18px;
    line-height: 28px;
}

@media (max-width: 1024px) {
    .formContainer {
        flex-direction: column;
    }

    div.inputContainer,
    input.input {
        width: 100%;
    }

    div.inputContainer {
        margin-top: 18px;
    }

    .button {
        width: 100%;
        margin-top: 18px;
    }

    .result {
        padding-top: 15px;
    }
}

/* // depends on sectionHeader.module */
@media (max-width: 991.98px) {
    .formContainer {
        padding: 0 20px;
    }

    .resultContainer {
        margin: 10px 20px 0;
        min-height: 192px;
    }

    .head {
        padding-top: 3px;
    }

    .description {
        padding-top: 10px;
        font-size: 16px;
        line-height: 24px;
    }
}
