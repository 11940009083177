.imageBlock {
    --wrapper-desktop-padding-left: 52px;
    --wrapper-desktop-padding-right: 52px;
    --wrapper-mobile-padding-left: 20px;
    --wrapper-mobile-padding-right: 20px;
    --wrapper-mobile-margin-bottom: 23px;
    --header-display: none;
    --card-padding: 14px;
    --card-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    --card-border-radius: 0px;
    --card-mobile-width: 335px;
    --card-mobile-image-width: 307px;
    --card-mobile-image-height: 249px;
    --card-title-color: var(--col--dark);
    --card-subtitle-color: var(--col--primary1);
    --card-description-color: var(--col--dark);
    --card-description-transform: none;
    --card-description-font-weight: 400;
    --card-button-padding: 12px;
    --card-button-max-width: calc(100% - 56px);
    --card-description-webkit-line-clamp: none;

    /* card2 */
    --card2-background: var(--col--light);
    --card2-desktop-padding: 25px;
    --card2-desktop-image-width: 235px;
    --card2-desktop-image-height: auto;
    --card2-desktop-content-padding-top: 21px;
    --card2-desktop-content-padding-bottom: 0;
    --card2-padding-bottom: 14px;
    --card2-button-max-width: calc(50% - 91px);
    --card2-subtitle-font-size: 20px;
    --card2-subtitle-line-height: 22px;

    /* card1 */

    /* image-width - padding-top - padding-bottom */
    --card1-desktop-image-width: calc(362px - 28px);
    --card1-desktop-title-font-size: 40px;
    --card1-desktop-title-line-height: 40px;
    --card1-desktop-subtitle-font-size: 34px;
    --card1-desktop-subtitle-line-height: 34px;

    /* card3 */
    --card3-desktop-max-width: 432px;
    --card3-desktop-image-width: 404px;
    --card3-desktop-image-max-width: auto;
    --card3-desktop-card-width: 100%;
    --card3-content-padding: 29px 16px 48px;
    --card3-content-mobile-padding: 29px 16px 48px;
    --card3-content-max-width: 372px;
    --card3-desktop-margin-right: 10px;
    --card3-desktop-title-margin: 0 0 15px;
    --card3-mobile-title-margin: 0 0 15px;
    --card3-description-max-width: 279px;

    /* header */
    --header-desktop-margin-bottom: 20px;
    --header-mobile-margin-bottom: 20px;

    /* card grid2,3 */
    --grid-card-margin-top: 30px;

    /* link title, subtitle */
    --title-text-transform: uppercase;
    --title-white-space: nowrap;
}
