.content {
    --header-background-color: var(--col--dark);
    --header-padding: 60px 20px 12px;
    --modalTitle-color: var(--col--light);
    --buttonsBlock-background-color: var(--col--dark);
    --closeIcon-color: var(--col--light);
    --closeIcon-top: 20px;
    --closeIcon-right: 20px;
    --buttonsBlock-padding: 0;
    --modalTitle-text-transform: uppercase;
    --dateLabel-text-transform: uppercase;
    --terms-margin-top: 23px;
    --content-max-height: 715px;
    --mainContent-padding: 39px 40px 0 54px;
    --terms-max-width: 432px;
    --terms-padding: 34px 20px 53px;
    --content-border-radius: 0px;
    --title-margin-mobile: 0 0 15px;
    --button-block-hint-flex-direction: column;
}

@media (min-width: 960px) {
    .content {
        --buttonsBlock-padding: 28px 39px 29px 0;
        --header-padding: 93px 0 30px 40px;
    }
}
