.wrapper {
    composes: infoBanner from './index.vars.css';
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: var(--banner-paddings);
}

h3.title {
    margin: 0;
    text-align: center;
    font-size: var(--description-title-font-size);
    line-height: var(--description-title-line-height);
    text-transform: var(--description-title-text-transform);
    font-style: var(--description-title-font-style, unset);
}

h2.title {
    margin: 0;
    text-align: center;
    font-size: var(--description-main-font-size);
    line-height: var(--description-main-line-height);
}

.description {
    margin-top: var(--description-margin-top);
}

.title {
    padding: 0;
    margin: 0;
    font-family: var(--description-title-font-family);
}

@media screen and (max-width: 959px) {
    .banner {
        padding: var(--banner-paddings-mobile);
    }

    .description {
        margin-top: var(--description-margin-top-mobile);
    }
}
