.sectionLayout {
    composes: sectionLayout from './miniBanners.vars.css';
    margin-top: 30px;
}

.sectionFlexContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: start;
}

.miniBanners {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}

.miniBanner {
    border: 1px solid var(--col--gray4);
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    max-width: 1336px;
    display: flex;
    align-items: center;
    margin-bottom: var(--miniBannerMarginBottom);
}

.miniBanner:last-child {
    margin-bottom: 0px;
}

.miniBannerContent {
    width: 100%;
    text-align: left;
    padding: 0 41px;
}

.title {
    margin: 0;
}

.secondaryTitle {
    margin: 0;
    font-weight: 800;
}

.sectionLayout .secondaryTitle {
    color: var(--secondaryTitleColor);
}

.description {
    margin: 4px 0 28px;
}

.buttonSection {
    display: flex;
}

.buttonSection img {
    height: var(--miniBannerImageButtonHeightDesktop);
}

.buttonSection a {
    margin-right: 20px;
}

.buttonSection a:last-child {
    margin-right: 0;
}

@media (min-width: 768px) {
    .title {
        font-size: var(--miniBannerTitleSizeLargeViewport);
        line-height: var(--miniBannerTitleSizeLargeViewport);
    }
}

@media (max-width: 767px) {
    .sectionFlexContainer {
        width: 100%;
    }

    .miniBanner {
        margin-bottom: 44px;
        flex-wrap: wrap;
    }

    .miniBanner:last-child {
        margin-bottom: 0px;
    }

    .miniBannerContent {
        padding-top: 24px;
        padding-bottom: 25px;
        text-align: center;
    }

    .buttonSection {
        height: 50px;
        position: absolute;
        left: 0;
        right: 0;
        justify-content: center;
        display: flex;
    }

    .buttonSection img {
        height: var(--miniBannerImageButtonHeight);
        min-width: var(--miniBannerImageButtonMinWidth);
    }

    .miniBanners {
        margin-top: 20px;
    }

    .miniBanners img,
    .miniBanners picture {
        width: 100%;
    }

    .secondaryTitle {
        font-size: 18px;
        line-height: 18px;
    }

    .description {
        margin: 4px 0 17px;
    }
}

@media (max-width: 425px) {
    .sectionLayout {
        padding-left: var(--sectionPaddingLeftVerySmallViewport);
    }
}
