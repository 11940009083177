.locationNewsTile {
    display: flex;
    flex-direction: column;
    max-width: 45%;
    margin-bottom: 54px;
}

.tileHeaderWrapper {
    display: flex;
    align-content: center;
}

.tileHeader {
    display: flex;
    align-self: center;
    justify-self: center;
    font-family: var(--ff--subheader);
    font-style: normal;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-left: 15px;
    color: var(--col--dark);
}

.tileText {
    font-family: var(--ff--pharagraph);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--col--dark);
    padding: 12px 0 0 35px;
}

@media (max-width: 768px) {
    .locationNewsTile {
        max-width: 100%;
    }

    .tileText {
        padding-left: 0px;
    }
}

@media (max-width: 600px) {
    .locationNewsTile {
        margin-bottom: 40px;
    }

    .tileHeader {
        font-size: 24px;
        line-height: 24px;
    }

    .tileText {
        font-size: 16px;
        line-height: 20px;
    }
}
