.container {
    composes: RecentOrderItem from './recentOrderItem.vars.css';
    height: var(--recentOrderItem-container-mobile-height);
    width: var(--recentOrderItem-container-width);
    box-sizing: border-box;
    border-radius: var(--recentOrderItem-container-border-radius);
    padding: 20px 15px;
    background-color: var(--col--light);
    border: var(--recentOrderItem-container-border);
}

.container:last-child {
    margin-right: 21px;
}

.container:first-child {
    margin-left: 21px;
}

.container:not(:last-child) {
    margin-right: 12px;
}

.receivedBorder {
    border-left: 3px solid var(--col--primary1);
}

.infoBlock {
    margin-bottom: 12px;
    line-height: var(--recentOrderItem-infoBlock-line-height);
}

.infoBlock .date {
    display: inline-block;
    background-color: var(--col--dark);
    font-size: var(--recentOrderItem-date-font-size);
    line-height: var(--recentOrderItem-date-line-height);
    font-weight: var(--recentOrderItem-date-font-weight);
    color: var(--recentOrderItem-date-color);
    text-transform: var(--recentOrderItem-date-text-transform);
    margin-right: var(--recentOrderItem-date-margin-right);
    padding: var(--recentOrderItem-date-padding);
    border-radius: var(--recentOrderItem-date-border-radius);
    font-family: var(--recentOrderItem-date-font-family);
}

.infoBlock .date.receivedBackground {
    background-color: var(--col--primary1);
}

.infoBlock .date.cancelledBackground {
    background-color: var(--col--gray5);
}

.orderContent {
    margin-bottom: var(--recentOrderItem-orderContent-margin-bottom);
    display: var(--recentOrderItem-orderContent-display);
    align-items: center;
    height: var(--recentOrderItem-orderContent-height);
}

.orderImage {
    padding: 3px 0px;
    margin-right: 12px;
    max-height: var(--recentOrderItem-orderContent-height);
}

.orderProducts {
    overflow: hidden;
}

.orderBlock {
    color: var(--recentOrderItem-orderBlock-color);
}

.unavailableItems {
    color: var(--col--primary1);
    font-size: var(--recentOrderItem-unavailableItems-font-size);
}

.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
}

.ctaBlock {
    display: flex;
    align-items: center;
}

.ctaBlock button {
    margin-right: 12px;
}

.selectedStall {
    font-size: 14px;
    color: var(--col--primary1);
}

.ctaBlock button:nth-child(2).cancelButton {
    border-left: 1px solid var(--col--gray4);
    padding-left: 12px;
}

.cancelButton {
    color: var(--col--primary1);
}

@media (min-width: 768px) {
    .container {
        height: var(--recentOrderItem-container-height);
    }

    .container:not(:last-child) {
        margin-right: 20px;
    }

    .container:last-child {
        margin-right: 0;
    }

    .container:first-child {
        margin-left: 0;
    }
}
