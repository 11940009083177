.topPicks {
    display: flex;
    position: relative;
    margin-top: 40px;
    width: stretch;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 833px;
    height: 143px;
    padding: 50px 0;
    z-index: -1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bgBlock {
    position: absolute;
    width: 833px;
    height: 243px;
    z-index: -1;
    margin-top: 40px;
}

.wrapperHeadings {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subtitle {
    font-family: var(--subhead-font);
    text-transform: uppercase;
    font-size: 34px;
    line-height: 34px;
    letter-spacing: 0.01em;
    padding-left: 50px;
    width: 200px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.title {
    width: 150px;
    margin: 0;
    font-family: var(--head-font);
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
    word-wrap: break-word;
    padding-left: 50px;
    color: var(--col--light);
}

.topPicksContainer {
    width: 100%;
}

.itemsWrapper {
    margin-left: 263px;
    margin-right: -20px;
}

.itemsWrapperGrid {
    width: stretch;
}

.prevButton {
    left: -5px;
}

.nextButton {
    right: -5px;
}

.items,
.itemsGrid {
    display: flex;
    padding: 30px 0 10px 20px;
    overflow: hidden;
}

@media (min-width: 768px) {
    .topPicks {
        padding-right: 52px;
    }

    .items::after {
        content: '';
        flex: 0 0 30px;
    }
}

@media (max-width: 1024px) {
    .content {
        width: 100%;
    }
}

@media (min-width: 1441px) {
    .topPicks {
        padding: 0 292px;
    }

    .title {
        padding-left: 290px;
    }

    .subtitle {
        padding-left: 290px;
    }

    .itemsWrapper {
        margin-left: 211px;
    }
}

@media (max-width: 767px) {
    .wrapperHeadings {
        min-height: 54px;
    }

    .items,
    .itemsGrid {
        padding: 40px 20px 10px;
    }

    .topPicks {
        margin-top: 0;
    }

    .content {
        width: 100%;
        height: 220px;
        padding: 28px 0 50px;
        z-index: -1;
        box-sizing: border-box;
        display: block;
    }

    .bgBlock {
        height: 220px;
        width: 100%;
    }

    .subtitle {
        font-size: 24px;
        line-height: 24px;
        padding-left: 20px;
        width: auto;
    }

    .title {
        width: auto;
        font-size: 28px;
        line-height: 28px;
        padding-left: 20px;
    }

    .itemsWrapper {
        padding: 0;
        margin: 0;
        margin-top: 55px;
    }

    .itemsWrapperGrid {
        display: flex;
        justify-content: center;
        margin: 0;
    }

    .itemsGrid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        margin: 0;
        min-width: 355px;
        overflow-x: initial;
        padding: 110px 10px 0;
        box-sizing: border-box;
    }

    .itemGrid {
        margin-right: 0;
    }

    .topPicks .items {
        overflow-x: auto;
    }
}

@media (max-width: 374px) {
    .itemsGrid {
        display: flex;
        flex-wrap: wrap;
        width: 296px;
        min-width: 296px;
        margin-left: 10px;
    }
}
