.container {
    composes: container from './withoutAccordion.vars.css';
    padding: 0 52px;
}

.container:first-child {
    border-top: solid 1px var(--col--gray4);
}

.subItem {
    display: flex;
    flex-direction: column;
    padding: 26px 0;
    margin-right: 20px;
    box-sizing: border-box;
    border-bottom: solid 1px var(--col--gray4);
}

.subItem > .heading {
    margin-bottom: 5px;
    max-width: 752px;
    font-family: var(--subItem-heading-font-family);
    font-size: var(--subItem-heading-font-size);
    font-weight: var(--subItem-heading-font-weight);
}

.subItem:first-child {
    border-top: solid 1px var(--col--gray4);
}

@media (max-width: 960px) {
    .container {
        padding: 0 20px;
    }
}

.answer p {
    max-width: 666px;
}

.answer a {
    font-family: var(--base-font);
    text-decoration: underline;
}
