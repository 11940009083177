.sectionFlexContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 59px 53px 0;
    box-sizing: border-box;
}

.mainContent {
    display: flex;
    flex-flow: row;
    margin-top: 28px;
    width: 100%;
}

.column {
    width: 100%;
}

.column:first-child {
    margin-right: 50px;
}

.header {
    font-weight: 900;
}

.infoColumn {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    padding-right: 97px;
}

.secondaryTitle {
    display: inline-flex;
    margin-top: 18px;
    font-weight: 800;
}

.secondarySubTitle {
    display: inline-flex;
    font-weight: 900;
    margin-bottom: 15px;
}

.secondaryDescription {
    font-weight: 500;
    max-width: 582px;
}

@media (max-width: 900px) {
    .sectionFlexContainer {
        padding: 59px 53px;
    }

    .mainContent {
        flex-direction: column;
    }

    .infoColumn {
        padding-right: 0;
    }
}

@media (max-width: 425px) {
    .sectionFlexContainer {
        padding: 40px 20px;
    }

    .mainContent {
        flex-direction: column;
    }

    .column:first-child {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .infoColumn {
        padding-top: 0;
        padding-right: 0;
    }

    .secondarySubTitle {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
}
