.section {
    margin-top: 80px;
    padding: 0 20px;
}

@media (max-width: 600px) {
    .section {
        margin-top: 40px;
    }
}

@media (min-width: 1025px) {
    .section {
        padding-left: 60px;
        padding-right: 50px;
    }
}

@media (min-width: 1200px) {
    .section {
        padding-left: 156px;
    }
}

@media (min-width: 1441px) {
    .section {
        padding-left: 396px;
        padding-right: 290px;
    }
}

.videoSection {
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .videoSection {
        height: 537px;
    }
}

@media screen and (min-width: 1025px) {
    .videoSection {
        height: 499px;
    }
}

@media screen and (max-width: 1024px) {
    .videoSection {
        height: 285px;
    }
}

@media screen and (max-width: 767px) {
    .videoSection {
        height: 319px;
    }
}

.playerWrapper {
    position: relative;

    /* 720 / 1280 = 0.5625 */

    /* padding-top: 56.25%;  */
}

.reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
}
